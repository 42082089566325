import { render, staticRenderFns } from "./friendLinks.vue?vue&type=template&id=79ee6e0e&scoped=true"
import script from "./friendLinks.vue?vue&type=script&lang=js"
export * from "./friendLinks.vue?vue&type=script&lang=js"
import style0 from "./friendLinks.vue?vue&type=style&index=0&id=79ee6e0e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_w5mlwnpxjkchi5v7ybhhph3oqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ee6e0e",
  null
  
)

export default component.exports