<template>
  <el-dialog title="" :visible.sync="visible" width="550px" :before-close="close">
    <div class="container">
      <div>
        <SendQuestionBox
          ref="sendBox"
          :on-ok="onAddQuestion"
          placeholder="写下你的问题，准确地描述问题更容易得到解答"
          send-btn-text="提问"
          @focus="handleFocusBox"
          :show-video="false"
        ></SendQuestionBox>
      </div>
      <div class="title" @click="openMore">大家都在问<img src="@/assets/images/ques-arrow-right.png" alt="" /></div>
      <div class="ques-list" v-if="list.length">
        <a
          v-for="item in list"
          :key="item.id"
          :href="getJumpWikiDetailUrl(item.game_alias || item.game.alias, item.game_id || item.game.id, item.id)"
          target="_blank"
        >
          <div class="item">
            <div class="item-title">{{ item.title }}</div>
            <div class="bottom">
              <div class="info">
                <span v-if="item.comment_count">{{ formatCount(item.comment_count) }}回复 · </span>
                <span>{{ formatNow(item.comment_at) }}</span>
              </div>
              <div class="user">
                <span>{{ item.user.username }}</span>
                <img class="avatar" :src="item.user.avatar" alt="" />
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="empty-box" v-else>~暂无提问~</div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { apiContentTopList, apiContentEdit } from "@/api/contentApi";
import dayjs from "dayjs";
import SendQuestionBox from "./sendQuestionBox.vue";
// plugins
import { getJumpWikiDetailUrl } from "@/plugins/util";

export default {
  components: { SendQuestionBox },
  data() {
    return {
      visible: false,
      list: []
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  mounted() {
    const cacheQuestionData = sessionStorage.getItem("question_content");
    if (cacheQuestionData) {
      const params = JSON.parse(cacheQuestionData);
      this.publishQuestion(params);
    }
  },
  methods: {
    getJumpWikiDetailUrl,
    open() {
      this.visible = true;
      this.getList();
    },
    close() {
      this.visible = false;
    },
    getList() {
      const params = {
        page_no: 1,
        limit: 5,
        type: 17, // 提问帖子
        order_by: "comment_at"
      };
      apiContentTopList(params).then(res => {
        console.log(res);
        if (res && res.code === 0) {
          this.list = res.data;
        }
      });
    },
    formatCount(count) {
      if (count >= 1000 && count < 10000) {
        return Math.floor(count / 1000) + "k+";
      } else if (count > 10000) {
        return Math.floor(count / 10000) + "w+";
      }
      return count;
    },
    formatNow(time) {
      const date = dayjs(time * 1000);
      const now = dayjs();
      const seconds = now.diff(date, "second");
      const minute = now.diff(date, "minute");
      const hours = now.diff(date, "hour");
      const day = now.diff(date, "day");
      if (seconds < 60) {
        return "刚刚";
      }
      if (minute < 60) {
        return minute + "分钟前";
      }
      if (hours < 24) {
        return hours + "小时前";
      }
      if (day < 7) {
        return day + "天前";
      }
      return date.format("YYYY-MM-DD HH:mm");
    },
    onAddQuestion(data) {
      const { text = "", thumb, contentJson } = data;
      let params = {
        editor_type: 1, // 新编辑器
        content_json: JSON.stringify(contentJson),
        summary: text.slice(0, 100),
        title: text.length > 50 ? text.slice(0, 50) + "..." : text,
        type: 17, // 提问
        thumb: thumb.join(",")
      };
      sessionStorage.setItem("question_content", JSON.stringify(params));
      if (!this.isLoginShow("")) return false;
      if (!this.isAuthRealName("")) return false;
      // 发布
      this.publishQuestion(params);
    },
    publishQuestion(params) {
      apiContentEdit(params).then(res => {
        if (res && res.code === 0) {
          this.$message.success("发布成功");
          sessionStorage.removeItem("question_content", JSON.stringify(params));
          // 清空数据
          this.$refs.sendBox.onClear();
          // 获取问题列表
          this.getList();
        }
      });
    },
    openMore() {
      this.close();
      this.$router.push(`/${this.gameInfo.alias}/list?tab=17`);
    },
    handleFocusBox() {}
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 0;
  .el-dialog__headerbtn {
    top: 6px;
    right: 6px;
  }
}
/deep/ .el-dialog__body {
  padding: 22px;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
}
.ques-list {
  width: 100%;
  .item {
    width: 100%;
    background: #f8f8f8;
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    margin-bottom: 10px;
    .item-title {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
    }
    .bottom {
      padding-top: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        font-size: 12px;
        color: #999;
        line-height: 14px;
      }
      .user {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        line-height: 14px;
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 6px;
        }
      }
    }
  }
}
.empty-box {
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
</style>
