import { mapGetters } from "vuex";
import { uploadFile } from "@/plugins/uploadFile";
import { uploadFileMulti } from "@/plugins/uploadFileMulti";
import { dataURLtoFile } from "@/plugins/util";

export default {
  props: {
    // 编辑框占位文案
    placeholder: {
      type: String,
      default: "说点什么吧"
    },
    customStyle: {
      type: Object,
      default: () => ({})
    },
    // 确认按钮文案
    sendBtnText: {
      type: String,
      default: "发 布"
    },
    // 是否显示上传视频按钮
    showVideo: {
      type: Boolean,
      default: true
    },
    // 是否显示上传视频按钮
    showImg: {
      type: Boolean,
      default: true
    },
    isShowEdit: {
      type: Boolean,
      default: true
    },
    // 确定按钮回调
    onOk: {
      type: Function,
      default: null
    }
  },
  model: {},
  components: {},
  data() {
    return {
      resources: []
    };
  },
  computed: {
    ...mapGetters(["gameInfo", "loginUser"])
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    uploadImg(e) {
      let file = e.target.files[0];
      let item = {
        file: file,
        percent: 0,
        type: "img",
        url: "",
        key: parseInt(Math.random() * 1000000)
      };
      this.resources.push(item);
      uploadFile({
        file,
        percent: 0,
        progressCb: percent => {
          item.percent = percent;
        }
      }).then(res => {
        item.percent = 100;
        item.url = res.url;
      });
    },
    async uploadVideo(e) {
      let file = e.target.files[0];
      let item = {
        file: file,
        percent: 0,
        type: "video",
        videoUrl: "",
        url: "",
        key: parseInt(Math.random() * 1000000)
      };
      this.resources.push(item);
      await this.getVideoPoster(file).then(base64Url => {
        // console.log(base64Url);
        if (base64Url.length > 17) {
          let imgFile = dataURLtoFile(base64Url, "img.png");
          uploadFile({
            file: imgFile,
            waterMark: 0,
            is_video: true
          }).then(res => {
            item.url = res.url;
          });
        }
      });
      let params = {
        file,
        is_video: true,
        progressCb: function(percent) {
          item.percent = percent;
        }
      };
      uploadFileMulti(params).then(res => {
        item.percent = 100;
        item.videoUrl = res.url;
      });
    },
    getVideoPoster(file) {
      console.log(file);
      return new Promise((resolve, reject) => {
        let url = window.URL.createObjectURL(file);
        let videoDom = document.createElement("video");
        videoDom.setAttribute("crossOrigin", "Anonymous");
        // videoDom.currentTime = 1;
        videoDom.muted = true;
        videoDom.autoplay = true;
        videoDom.addEventListener("loadeddata", function() {
          let h = videoDom.videoHeight;
          let w = videoDom.videoWidth;
          if (w > 500) {
            h = (500 / w) * h;
            w = 500;
          }
          console.log(h, w);
          let canvas = document.createElement("canvas");
          canvas.width = w;
          canvas.height = h;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(videoDom, 0, 0, w, h);
          let _url = canvas.toDataURL("image/png");
          resolve(_url);
        });
        videoDom.src = url;
      });
    },
    onCloseImg(index) {
      this.resources.splice(index, 1);
    },

    _onOk() {
      let isUpdate = true;
      this.resources.forEach(item => {
        if (item.percent < 100) {
          isUpdate = false;
        }
      });
      if (!isUpdate) {
        this.$message("还有资源未上传完成");
        return;
      }
      const text = this.$refs.textBox.innerText || "";
      if (text.trim() == "") {
        this.$message("内容为空，请重新输入！");
        return;
      }
      const data = this.formatData();
      if (this.onOk) {
        this.onOk(data);
      }
    },
    onClear() {
      this.$refs.textBox.innerHTML = "";
      this.resources = [];
    },
    handleInput() {
      const text = this.$refs.textBox.innerText;
      if (text.length === 1 && !text.trim()) {
        this.$refs.textBox.innerHTML = "";
      }
    },
    handleFocus() {
      this.$emit("focus");
    },
    formatData() {
      const text = this.$refs.textBox.innerText || "";
      console.log(1 + text);
      let contentJson = [{ type: "paragraph", children: [{ text: text }] }];
      let thumb = [];
      this.resources.forEach(item => {
        if (item.type == "img") {
          // 一行一个
          const imgJson = {
            type: "paragraph",
            children: [
              { text: "" },
              {
                type: "image",
                key: Math.random()
                  .toString(8)
                  .slice(2),
                src: item.url,
                alt: "",
                isAdaptive: true,
                style: {},
                children: [{ text: "" }]
              },
              { text: "" }
            ]
          };
          contentJson.push(imgJson);
          if (thumb.length < 3) {
            thumb.push(item.url);
          }
        } else if (item.type == "video") {
          const videoJson = {
            type: "video",
            src: item.videoUrl,
            poster: item.url,
            children: [{ text: "" }]
          };
          contentJson.push(videoJson);
        }
      });

      return {
        text,
        resources: this.resources,
        contentJson,
        thumb
      };
    }
  }
};
