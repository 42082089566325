import Vue from "vue";
import { mapGetters } from "vuex";
import eventBus from "@/plugins/eventBus";
import jsToApp from "../plugins/jsToApp";

Vue.mixin({
  data() {
    return {
      wikiDomain: process.env.VUE_APP_WIKI_DOMAIN,
      imgSize: {
        mini: "?x-image-process=image/resize,m_lfit,h_50,w_50&image_process=resize,mid,w_50,h_50",
        small: "?x-image-process=image/resize,m_lfit,h_100,w_100&image_process=resize,mid,w_100,h_100",
        icon: "?x-image-process=image/resize,m_lfit,h_200,w_200&image_process=resize,mid,w_200,h_200",
        list: "?x-image-process=image/resize,m_lfit,h_300,w_300&image_process=resize,mid,w_300,h_300",
        middle: "?x-image-process=image/resize,m_lfit,w_800&image_process=resize,mid,w_800",
        hd: "?x-image-process=image/resize,m_lfit,w_1600&image_process=resize,mid,w_1600"
      }
    };
  },
  computed: {
    ...mapGetters([
      "alias",
      "isWww",
      "device",
      "loginUid",
      "loginUser",
      "isLoadRoleInfo",
      "pageSource",
      "isHeroGame",
      "needLogin",
      "isHorizontal"
    ]),
    classBase() {
      return this.device.desktop ? "pc-" : "wap-";
    }
  },
  created() {},
  beforeMount() {
    // 服务端渲染，需后执行
    this.getSource();
  },

  methods: {
    /**
     * 权限检查方法
     * @param value  需要检查的参数
     * @param strict 是否为严格模式 非严格模式 满足其中一个返回true,严格模式需要全部满足
     * @returns {boolean}
     */
    $hasPermission(value, strict = false) {
      // console.log(value);
      if (!value) {
        return false;
      }
      if (!(value instanceof Array)) {
        value = [value];
      }
      let isHas = false;
      let count = 0;
      // 拉取vuex所有按钮权限
      // console.log(btnPermission);
      const btnPermission = this.$store.getters.roleInfo || [];
      // // 通过本地对比知道是否有权限
      value.forEach(item => {
        if (btnPermission.includes(item)) count++;
      });
      if (count > 0) {
        isHas = true;
      }
      if (strict && count != value.length) isHas = false;
      return isHas;
    },
    /**
     * 处理图片尺寸
     * @param url 图片地址
     * @param size 图片尺寸 默认list, 其他参数参考上面imgSize
     * @returns {string|*}
     */
    handleImgSize(url, size = "list", defaultUrl) {
      if (!url) {
        return defaultUrl || require("../assets/images/local.png");
      }
      if (url.includes("x-image-process") || url.includes("x-oss-process")) {
        url = url.split("?")[0];
      }
      if (url.includes("thirdqq") || url.includes("data:image")) {
        return url;
      } else {
        return url + this.imgSize[size];
      }
    },
    /**
     * 判断是否登录
     * @param msg 自定义提示信息
     * @returns {boolean}
     */
    isLogin(msg = "") {
      let isLogin = !(!this.loginUid || this.loginUid == 0);
      if (msg) {
        this.$message(msg);
      }
      return isLogin;
    },
    /**
     * 判断是否登录，未登录则显示登录弹窗
     * @param msg 自定义提示信息
     * @returns {boolean}
     */
    isLoginShow(msg = "", loginType = "normal") {
      const isLogin = this.isLogin();
      if (!isLogin) {
        eventBus.$emit("onShowLogin", loginType);
        if (msg) {
          this.$message(msg);
        }
      }
      return isLogin;
    },
    /**
     * 判断是否实名认证
     * @param msg 自定义提示信息
     * @returns {boolean}
     */
    isAuthRealName(msg = "") {
      return true
      // let isLogin = this.isLogin();
      // if (!isLogin) return false;
      // let isAuth =
      //   (this.loginUser.verify_level == 1 && !this.loginUser.verify_status) || this.loginUser.verify_level == 2;
      // if (!isAuth) {
      //   eventBus.$emit("onShowAuthRealName");
      //   if (msg) this.$message(msg);
      // }
      // return isAuth;
    },
    /**
     * 处理内外链接以及APP内链接处理
     * @param url 需要跳转的url
     * @param type
     */
    handleLinks(url = "", type = "default") {
      if (!url) return;
      // 不包含yingxiong.com或者gamekee.com 统一视为外部链接
      if (url.includes("yingxiong.com") || url.includes("gamekee.com")) {
        if (this.device.inApp) {
          this.jsToApp("app_openUrl", url);
        } else {
          window.open(url);
        }
      }
      //  内部链接
    },
    /**
     * 网页调取调用APP方法
     * @param name 方法名，网页端与APP端约定的名称
     * @param params 向APP传递的参数
     */
    jsToApp(name, params = "") {
      jsToApp(name, params);
    },
    // 获取页面来源
    getSource() {
      if (this.$route) {
        this.$store.commit("set_pageSource", this.$route.query.source || "gamekee");
      }
    }
  }
});
