// import './esdk-obs-browserjs-3.19.5.min.js';
// import ObsClient from './esdk-obs-browserjs-3.19.5.min.js';
// import store from '@/store/index';
import Vue from "vue";
import dayjs from "dayjs";
import { apiGetObsClientSecurityToken } from "@/api/publicApi";
import { getCookies, setCookies } from "./cookies";

/**
 *
 * @param {*} params
 * let params = {
 * file:file
 * accept:'png,gif',  //多个用,隔开
 * progressCb:(percent)=>{  //进度回调
 *
 *  },
 *  uploadingCb:()=>{ //上传中的回调
 *
 * }
 * uploadFileMulti(params)
 *      .then(res => {
 *         console.log(res)
 *       })
 *       .catch(err => {
 *         console.log(err)
 *       })
 */

async function uploadFileMulti(params) {
  const store = Vue.__VUEX_STORE__;
  let obsClinetParams = getCookies("obs_client_params") ? JSON.parse(getCookies("obs_client_params")) : null;
  if (!obsClinetParams) {
    try {
      const res = await apiGetObsClientSecurityToken();
      if (res && res.code == 0) {
        const { access, secret, security_token, bucket_name, end_point } = res.data;
        obsClinetParams = {
          access_key_id: access,
          secret_access_key: secret,
          security_token: security_token,
          server: end_point,
          bucket_name: bucket_name
        };
        setCookies("obs_client_params", JSON.stringify(obsClinetParams), {
          expires: new Date(Date.now() + 60 * 60 * 1000)
        });
      } else {
        return Promise.reject("上传token获取异常");
      }
    } catch (error) {
      return Promise.reject("上传token获取异常", error);
    }
  }
  return new Promise((resolve, reject) => {
    params.obsClientData = {
      // access_key_id: 'C8TAVPQ63LM4EY5AGXWL',
      // secret_access_key: 'bUlfIu1AQA1U9RJlxMMd70bzjTE02C3WazpCdlEq',
      // server: 'obs.cn-north-1.myhuaweicloud.com'
      access_key_id: obsClinetParams.access_key_id,
      secret_access_key: obsClinetParams.secret_access_key,
      security_token: obsClinetParams.security_token,
      server: obsClinetParams.server
    };
    params.bucketName = obsClinetParams.bucket_name || "wiki-obs";

    if (params.is_video) {
      params.objectKey = "wiki2.0/video/";
    } else {
      params.objectKey = "wiki2.0/images/";
    }
    console.log(store.getters.gameInfo);
    params.objectKey += store.getters.gameInfo.id + "/";
    params.objectKey += (getCookies("userUid") || 0) + "/";
    params.objectKey += dayjs().year() + "/";
    params.objectKey += dayjs().month() + "/";
    params.objectKey += dayjs().date() + "/";
    params.objectKey += Number.parseInt(Math.random() * 1000000);
    params.objectKey += fnGetExtension(params.file)[0];

    params.cancelUpload = false;
    if (params.key) {
      params.objectKey = params.key;
    }

    if (!params.file) {
      let errMsg = {
        msg: "请选择文件"
      };
      reject(errMsg);
      return false;
    }
    if (params.accept && !params.accept.includes(fnGetExtension(params.file)[1])) {
      let errMsg = {
        msg: `请上传符合以下格式的文件：${params.accept}`
      };
      reject(errMsg);
      return false;
    }
    params.obsClient = new ObsClient(params.obsClientData);
    let fileSize = params.file.size;
    let partSize = 1024 * 500;
    params.obsClient
      .initiateMultipartUpload({
        Bucket: params.bucketName,
        Key: params.objectKey
        // Expires: params.expires,
      })
      .then(function(result) {
        let uploadId = result.InterfaceResult.UploadId;
        let partCount =
          fileSize % partSize === 0 ? Math.floor(fileSize / partSize) : Math.floor(fileSize / partSize) + 1;
        let uploadPartStatus = {
          bucketName: params.bucketName,
          objectKey: params.objectKey,
          uploadId: uploadId,
          file: params.file,
          fileSize: fileSize,
          partSize: partSize,
          partCount: partCount,
          currentPartIndex: 0,
          parts: []
        };
        doUploadPart(params.obsClient, uploadPartStatus);
      })
      .catch(function(err) {
        console.error("err:" + err);
      });
    let globalContext = {};
    globalContext.maxTaskNum = 5;
    globalContext.currentTaskNum = 0;

    function doUploadPart(obsClient, uploadPartStatus) {
      while (
        uploadPartStatus.currentPartIndex < uploadPartStatus.partCount &&
        globalContext.currentTaskNum < globalContext.maxTaskNum
      ) {
        let offset = uploadPartStatus.currentPartIndex * uploadPartStatus.partSize;
        let currPartSize =
          uploadPartStatus.currentPartIndex + 1 === uploadPartStatus.partCount
            ? uploadPartStatus.fileSize - offset
            : uploadPartStatus.partSize;
        let partNumber = uploadPartStatus.currentPartIndex + 1;
        globalContext.currentTaskNum++;
        uploadPartStatus.currentPartIndex++;
        obsClient
          .uploadPart({
            Bucket: uploadPartStatus.bucketName,
            Key: uploadPartStatus.objectKey,
            PartNumber: partNumber,
            UploadId: uploadPartStatus.uploadId,
            SourceFile: uploadPartStatus.file,
            Offset: offset,
            PartSize: currPartSize
          })
          .then(function(result) {
            globalContext.currentTaskNum--;
            // 取消上传
            if (params.cancelUpload) return false;
            doUploadPart(obsClient, uploadPartStatus);
            if (result.CommonMsg.Status < 300) {
              // 上传进度；
              Progress(uploadPartStatus);
              uploadPartStatus.parts.push({
                PartNumber: partNumber,
                ETag: result.InterfaceResult.ETag
              });
              if (uploadPartStatus.parts.length === uploadPartStatus.partCount) {
                let _parts = uploadPartStatus.parts.sort(function(a, b) {
                  if (a.PartNumber >= b.PartNumber) {
                    return 1;
                  }
                  return -1;
                });
                obsClient.completeMultipartUpload(
                  {
                    Bucket: uploadPartStatus.bucketName,
                    Key: uploadPartStatus.objectKey,
                    UploadId: uploadPartStatus.uploadId,
                    Parts: _parts
                  },
                  function(err, result) {
                    console.log(err, result);
                    if (!err && result.CommonMsg.Status < 300) {
                      Progress(uploadPartStatus, "end");
                      console.log("上传成功");
                      result.key = params.objectKey;
                      result.url = "//" + store.getters.gameInfo.cdn_base_url + "/" + params.objectKey;
                      console.log("上传成功", result);
                      resolve(result);
                    } else {
                      let msg = err || result.CommonMsg.Code;
                      console.error(msg);
                      reject(msg);
                      // 回调错误
                      // params.error && params.error(msg);
                    }
                  }
                );
              }
            } else {
              console.error(result.CommonMsg.Code);
              // 回调错误
              reject(result);
            }
          })
          .catch(function(err) {
            console.error("err:" + err);
            // 回调错误
            reject(err);
          });
      }
    }

    // 回调进度
    function Progress(data, result) {
      // console.log(data);
      if (result && result == "end") {
        params.progressCb && params.progressCb(100);
        return;
      }
      // 当前切片
      let currentPartIndex = data.currentPartIndex;
      // 文件大小
      let fileSize = data.fileSize;
      // 分段大小
      let partSize = data.partSize;
      // 已上传大小
      let uploadSize = partSize * currentPartIndex;
      if (uploadSize > fileSize) uploadSize = fileSize;
      // 百分比进度
      let percent = ((uploadSize / fileSize) * 100).toFixed(2);
      if (!params.cancelUpload) {
        params.progressCb && params.progressCb(percent);
      }
      params.uploadingCb &&
        params.uploadingCb(function(cbParams) {
          if (cbParams && cbParams.cancelUpload) {
            params.cancelUpload = true;
          }
        });
    }
  });
}

function fnGetExtension(file) {
  let fileName = file.name;
  // eslint-disable-next-line
  let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  let fileExtension = fileName.match(patternFileExtension);
  return fileExtension;
}

export { uploadFileMulti };
