import service from '@/plugins/request';

// 筛选编辑
export function apiEntryFilterEdit (params) {
  let url = '/v1/protected/entryFilter/add';
  if (params.id && params.id > 0) {
    url = '/v1/protected/entryFilter/edit';
  }
  return service({
    url: url,
    method: 'post',
    data: params,
    successToast: true
  });
}

// 删除筛选条件
export function apiEntryFilterDel (params) {
  return service({
    url: '/v1/protected/entryFilter/delete',
    method: 'post',
    data: params,
    successToast: true
  });
}

// 筛选管理-保存词条的筛选属性
export function apiEntryFilterSaveEntryForm (params) {
  return service({
    url: '/v1/protected/entryFilter/saveEntryForm',
    method: 'post',
    data: params,
    successToast: true
  });
}

// 获取一个选项的所有筛选条件
export function apiEntryFilterGetAll (params) {
  return service({
    url: '/v1/protected/entryFilter/getAll',
    method: 'get',
    params: params
  });
}

// 筛选管理-词条配合信息
export function apiEntryFilterGetEntryForm (params) {
  return service({
    url: '/v1/protected/entryFilter/getEntryForm',
    method: 'get',
    params: params
  });
}

// 筛选管理-词条配合信息
export function apiEntryFilterGetEntryFilter (params) {
  return service({
    url: '/v1/entryFilter/getEntryFilter',
    method: 'get',
    params: params
  });
}

// 筛选管理-排序
export function apiEntryFilterSort (params) {
  return service({
    url: '/v1/protected/entryFilter/sortFilter',
    method: 'post',
    data: params,
    successToast: true
  });
}
