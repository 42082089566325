<template>
  <div class="pc-home-container">
    <!-- <wiki-nav/> -->
    <fixed-share :customStyle="{ marginLeft: '614px' }" @openQuestion="openQuestion" />

    <wiki-home-top />

    <div class="wiki-main-box" :class="alias + '-wiki-main-box'">
      <!-- 右侧悬浮按钮，分享 -->
      <right-banner :gameInfo="gameInfo" />
      <!-- 顶部导航栏 -->
      <top-nav-container :entryList="entryList" />
      <div class="wiki-home">
        <!-- 左侧内容区域 -->
        <div class="left-container">
          <!-- banner -->
          <banner-container v-if="modelsList[1].module.status" :list="modelsList[1].list || []" />
          <!-- 热门词条 -->
          <div v-if="modelsList[4].module.status" class="home-model-container hot-wiki-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("hot_entry") }}</span>
            </div>
            <home-item-wrapper :is-max-icon="2" :pItem="{ child: modelsList[4].list }" />
          </div>
          <!-- 普通词条 -->
          <template v-for="item in entryList">
            <home-model-container v-if="item.status == 1" :key="item.id" :item="item" />
          </template>
        </div>

        <!-- 右侧内容区域 -->
        <div class="right-container" :class="alias + '-right-container'">
          <!-- wiki公告 -->
          <div class="right-model-container game-detail-box" :class="alias + '-game-detail-box'">
            <div class="game-detail-title">{{ $t("wiki_notice") }}</div>
            <div class="game-detail">
              <div class="desc" v-html="xss(gameInfo.description)"></div>
            </div>
            <row-friend-links v-if="modelsList[2].module.status && false" :list="modelsList[2].list" />
          </div>
          <!-- app下载 -->
          <div
            v-if="modelsList[15]"
            class="right-model-container "
            :class="
              alias == 'ba' ? alias + '-app-download-card' + ' ' + 'ba-quyun-app-download-card' : 'app-download-card'
            "
          >
            <template v-if="alias != 'ba'">
              <div class="download-qrcode">
                <img src="@/assets/images/quyun-app-download-qrcode.png" alt="二维码" />
              </div>
              <div class="download-tips">
                <div class="title">登录就 “送” 高速云手机</div>
                <div class="desc">
                  <div>手游挂机，抢先体验国际服</div>
                  <div>扫码下载 去云吧 APP</div>
                </div>
              </div>
              <!-- <div class="card-tag"><i class="wiki-icon wiki-icon-android"></i>仅限安卓用户</div> -->
            </template>
          </div>
          <!-- <div
            v-if="modelsList[16]"
            class="right-model-container"
            :class="alias == 'ba' ? alias + '-app-download-card' : 'app-download-card'"
          >
            <div class="download-qrcode">
              <img src="@/assets/images/app-download-qrcode.png" alt="二维码" />
            </div>
            <div class="download-tips">
              <div class="title">扫码下载 GameKee APP</div>
              <div class="desc">
                <div>同好共聊，热火朝天!</div>
                <div>资讯wiki，快人一步!</div>
              </div>
            </div>
            <div class="card-tag"><i class="wiki-icon wiki-icon-android"></i>仅限安卓用户</div>
          </div> -->

          <!-- 官方公告 -->
          <div v-if="modelsList[6].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("official_notice") }}</span>
              <div class="right-wrapper">
                <router-link :to="`/${gameInfo.alias}/list?tab=2`" class="edit-btn">更多</router-link>
              </div>
            </div>
            <div class="right-model-content">
              <template v-for="(item, index) in modelsList[6].list">
                <router-link
                  v-if="index < 3"
                  :key="index"
                  :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.id, true)"
                  class="article-row-tag"
                >
                  <template v-if="lang == 'en'">
                    <span :class="['span-point', `span-point-${tagList[item.tag || 0].color || 'red'}`]"></span>
                  </template>
                  <template v-else>
                    <span :class="['tag-color', `tag-color-${tagList[item.tag || 0].color || 'red'}`]">{{
                      tagList[item.tag || 0].text
                    }}</span>
                  </template>
                  <span class="title">{{ item.title }}</span>
                </router-link>
              </template>
            </div>
            <notice-list-group v-if="false" :list="modelsList[6].list" class="right-model-content" />
          </div>
          <!-- 友情链接 -->
          <div v-if="modelsList[2].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("friendly_link") }}</span>
            </div>
            <div class="right-model-content" style="padding-bottom:4px;">
              <friend-links :list="modelsList[2].list" />
            </div>
          </div>
          <!-- wiki投稿 -->
          <div
            v-if="needLogin || loginUser.is_super"
            :class="['link-btn', bgStyle('pub_bbs_img') ? 'custom-img' : '']"
            :style="bgStyle('pub_bbs_img')"
            @click="goEdit"
          >
            <span>{{ $t("wiki_post") }} <i class="el-icon-arrow-right"></i></span>
          </div>
          <!-- 跳转社区 -->
          <router-link
            :class="['link-btn', bgStyle('bbs_img') ? 'custom-img' : '']"
            :style="bgStyle('bbs_img')"
            :to="`/${gameInfo.alias}/list`"
          >
            <span>{{ $t("talks") }} <i class="el-icon-arrow-right"></i></span>
          </router-link>
          <!-- 活动日历 -->
          <div v-if="modelsList[5].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("calendar") }}</span>
            </div>
            <calendar-container :list="modelsList[5].list" class="right-model-content" />
          </div>
          <!-- 攻略推荐 -->
          <div v-if="modelsList[8].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">攻略推荐</span>
            </div>
            <div class="right-model-content">
              <a v-for="(item, index) in modelsList[8].list" :key="index" :href="item.origin" class="article-row-tag">
                <span :style="{ background: item.tag_color }" class="tag-color">{{ item.tag }}</span>
                <span class="title">{{ item.title }}</span>
              </a>
            </div>
          </div>
          <!-- 常用词条 -->
          <div v-if="modelsList[9].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">常用词条</span>
            </div>
            <div class="right-model-content">
              <router-link
                v-for="(item, index) in modelsList[9].list"
                :key="index"
                :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.content_id, true)"
                class="article-item-btn"
              >
                <img v-if="item.icon" :src="item.icon" alt="" />
                <span>{{ item.name }}</span>
              </router-link>
            </div>
          </div>
          <!-- 词条更新 -->
          <div class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("entry_update") }}</span>
            </div>
            <div class="right-model-content">
              <template v-for="(item, index) in lastUpdateList">
                <router-link
                  v-if="index < 5"
                  :key="item.id"
                  :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.id, true)"
                  class="article-row-tag"
                >
                  <span class="tag-color tag-color-blue">更新</span>
                  <span class="title" v-html="xss(item.title)"></span>
                </router-link>
              </template>
            </div>
          </div>
          <!-- 社区 -->
          <div v-if="modelsList[7].module.status" class="right-model-container">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">{{ $t("community") }}</span>
              <div class="right-wrapper">
                <router-link class="edit-btn" :to="`/${gameInfo.alias}/list`">{{ $t("more") }}</router-link>
              </div>
            </div>
            <div class=" right-model-content">
              <send-article-box
                v-if="needLogin"
                ref="sendBox"
                :onOk="onSendBoxOk"
                @focus="handleFocusBox"
                :placeholder="loginUid > 0 ? '友善发帖，文明交流' : $t('login_to_post')"
              />
              <div v-if="needLogin" class="H20px"></div>
              <template>
                <article-list-item v-for="item in modelsList[7].list" :key="item.id" :item="item" layout="row" />
              </template>
              <div class="more-btn" style="margin-top: -12px">
                <router-link :to="`/${gameInfo.alias}/list`"
                  >{{ $t("more") }} <i class="wiki-icon-arrow-right"></i
                ></router-link>
              </div>
            </div>
          </div>
          <!-- 新手问答 -->
          <div class="right-model-container" v-if="modelsList[17]">
            <div :style="bgStyle('module_img')" class="model-title">
              <span class="title">新手问答</span>
              <div class="right-wrapper">
                <router-link class="edit-btn" :to="`/${gameInfo.alias}/list?tab=17`">{{ $t("more") }}</router-link>
              </div>
            </div>
            <div class="right-model-content">
              <div class="question-list">
                <a
                  v-for="item in questionList"
                  :key="item.id"
                  :href="
                    getJumpWikiDetailUrl(item.game_alias || item.game.alias, item.game_id || item.game.id, item.id)
                  "
                  target="_blank"
                >
                  <div class="item">
                    <span class="title">{{ item.title }}</span>
                    <i class="wiki-icon-arrow-right"></i>
                  </div>
                </a>
              </div>
              <div class="more-btn">
                <router-link :to="`/${gameInfo.alias}/list?tab=17`">
                  {{ $t("more") }}
                  <i class="wiki-icon-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
          <!-- 提问 -->
          <QuestionAdd ref="questionAdd"></QuestionAdd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// components
import HomeModelContainer from "./components/homeModelContainer";
import SendArticleBox from "./components/sendArticleBox";
import ArticleListItem from "./components/articleListItem";
import BannerContainer from "./components/bannerContainer";
import HomeItemWrapper from "./components/homeItemWrapper";
import FriendLinks from "./components/friendLinks";
import NoticeListGroup from "./components/noticeListGroup";
import TopNavContainer from "./components/topNavContainer";
import WikiHomeTop from "./components/wikiHomeTop";

import CalendarContainer from "@/views/wiki/components/calendarContainer";
import RowFriendLinks from "@/views/wiki/components/rowFriendLinks";
import RightBanner from "@/views/wiki/components/rightBanner";
import FixedShare from "@/views/wiki/components/fixedShare";
import QuestionAdd from "./components/questionAdd.vue";
// apis
import { apiContentEdit, apiContentTopList } from "@/api/contentApi";
import { apiWikiIndex, apiWikiEntry, apiWikiEntryLastUpdateList } from "@/api/wikiApi";
import { apiAdminApply } from "@/api/userApi";
// i18n
import { getLang } from "@/i18n";
// plugins
import { getJumpWikiDetailUrl } from "@/plugins/util";
// mixins
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

function getTagList($vm) {
  // console.log($vm);
  return {
    0: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    1: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    2: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    3: {
      color: "orange",
      text: $vm.$t("notice_tags.tag2")
    },
    4: {
      color: "green",
      text: $vm.$t("notice_tags.tag1")
    }
  };
}
function handleModelList(list) {
  let modelsList = {
    1: {
      list: [],
      module: {}
    },
    2: {
      list: [],
      module: {}
    },
    3: {
      list: [],
      module: {}
    },
    4: {
      list: [],
      module: {}
    },
    5: {
      list: [],
      module: {}
    },
    6: {
      list: [],
      module: {}
    },
    7: {
      list: [],
      module: {}
    },
    8: {
      list: [],
      module: {}
    },
    9: {
      list: [],
      module: {}
    }
  };
  // console.log(list);
  if (list) {
    list.forEach(item => {
      if (item.module.type == 6) {
        item.list && item.list.forEach(item => {
          item.tag = item.tag || "0";
        });
      }
      modelsList[item.module.type] = item;
    });
  }
  return modelsList;
}
export default {
  name: "wikiHome",
  mixins: [mergeAsyncDataMixin],
  props: {},
  model: {},
  components: {
    WikiHomeTop,
    TopNavContainer,
    RowFriendLinks,
    // WikiNav,
    CalendarContainer,
    NoticeListGroup,
    FriendLinks,
    HomeItemWrapper,
    BannerContainer,
    ArticleListItem,
    SendArticleBox,
    HomeModelContainer,
    RightBanner,
    FixedShare,
    QuestionAdd
  },
  data() {
    return {
      testAsyncData: "0",
      modelsList: handleModelList(),
      isSSRGetData: false,
      searchName: "",
      visibleSq: false,
      sqDesc: "",
      lang: "zh-cn",
      data: {},
      questionList: [],
      entryList: [],
      lastUpdateList: []
    };
  },
  async asyncData({ store, ssrContext, $apis }) {
    try {
      console.log("asyncData start");
      // TDK设置
      const gameInfo = store.getters.gameInfo;
      if (gameInfo.is_tdk && gameInfo.Config) {
        ssrContext.title = gameInfo.Config.seo_title;
        ssrContext.keywords = gameInfo.Config.seo_keywords;
        ssrContext.description = gameInfo.Config.seo_desc;
      } else {
        ssrContext.title = `${gameInfo.name}wiki官网_${gameInfo.name}图鉴|GameKee`;
        ssrContext.keywords = `${gameInfo.name}wiki,${gameInfo.name}官网,${gameInfo.name},${gameInfo.name}人物图鉴,${gameInfo.name}装备图鉴`;
        ssrContext.description = `${gameInfo.name}wiki官网为您带来${gameInfo.name}从新手到高手的一切攻略内容，这是由大神玩家们制作的${gameInfo.name}专属资料库，包含实时更新的${gameInfo.name}人物图鉴和${gameInfo.name}装备图鉴。`;
      }
      // 数据预取

      const params = {
        limit: 10,
        page_no: 0,
        page_total: 1,
        total: 0,
        type: "17", // 17-问答
        order_by: "",
        keyword: ""
      };
      const time = new Date().getTime();
      console.log(time);
      const res = await Promise.all([
        $apis.apiWikiIndex(),
        $apis.apiContentTopList(params),
        $apis.apiWikiEntry(),
        $apis.apiWikiEntryLastUpdateList()
      ]);
      console.log(new Date().getTime() - time);
      const { code: wikiResCode, data: wikiData } = res[0];
      let modelsList = handleModelList();
      if (wikiResCode == 0) {
        modelsList = handleModelList(wikiData);
      }
      const { code: questionResCode, data: questionData } = res[1];
      let questionList = [];
      if (questionResCode == 0) {
        questionList = questionData;
      }
      const { code: entryResCode, data: entryResData } = res[2];
      let entryList = [];
      if (entryResCode == 0) {
        entryList = entryResData.entry_list;
      }

      const { code: lastUpdateResCode, data: lastUpdateResData } = res[3];
      let lastUpdateList = [];
      if (lastUpdateResCode == 0) {
        lastUpdateList = lastUpdateResData;
      }

      console.log("asyncData end");
      return {
        modelsList,
        questionList,
        entryList,
        lastUpdateList,
        isSSRGetData: true,
        isSSRGetQuestionList: true
      };
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["gameInfo"]),
    tagList() {
      return getTagList(this);
    }
  },

  created() {},
  beforeMount() {
    this.lang = getLang();
    // 判断服务端是否已获取数据
    if (!this.isSSRGetData) {
      this.getIndexData();
      this.getEntryData();
      this.getQuestionData();
      this.getLastUpdateList();
    }
  },
  mounted() {
    // this.getNewVersionInfos();
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    getJumpWikiDetailUrl,
    openQuestion() {
      this.$refs.questionAdd.open();
    },
    handleFocusBox() {
      if (!this.isLoginShow("")) return false;
      if (!this.isAuthRealName("")) return false;
    },
    bgStyle(filed, type = "cover") {
      let config = this.gameInfo.Config || {};
      if (config[filed]) {
        return {
          background: `url(${config[filed]}) no-repeat left center`,
          backgroundSize: type
        };
      } else {
        return "";
      }
    },
    // ModuleTypeSlider     = 1 //轮播图模块
    // ModuleTypeFriendLink = 2 //友情链接
    // ModuleTypeAdminList  = 3 //管理员列表
    // ModuleTypeHotEntry   = 4 //热门词条
    // ModuleTypeActivity   = 5 //活动周历
    // ModuleTypeNotice     = 6 //公告
    // ModuleTypeBbs        = 7 //帖子
    getIndexData() {
      apiWikiIndex().then(({ data }) => {
        // this.handleModelList(data);
        this.modelsList = handleModelList(data);
      });
    },
    async getEntryData() {
      try {
        const { code, data } = await apiWikiEntry();
        if (code == 0) {
          this.entryList = data.entry_list || [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLastUpdateList() {
      try {
        const { code, data } = await apiWikiEntryLastUpdateList();
        if (code == 0) {
          this.lastUpdateList = data || [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getQuestionData() {
      try {
        const params = {
          limit: 10,
          page_no: 0,
          page_total: 1,
          total: 0,
          type: "17", // 17-问答
          order_by: "",
          keyword: ""
        };
        const { code, data } = await apiContentTopList(params);
        if (code == 0) {
          this.questionList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goSearch() {
      let { searchName } = this;
      if (searchName) {
        this.searchName = "";
        this.$router.push(`/${this.gameInfo.alias}/list?kw=${searchName}`);
      }
    },
    goEdit() {
      if (!this.isLoginShow("")) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      if (!this.isAuthRealName()) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      this.$router.push(`/${this.gameInfo.alias}/editor`);
    },
    onSendBoxOk(data) {
      if (!this.isLoginShow("")) return false;
      if (!this.isAuthRealName("")) return false;
      console.log(data);
      const { text, thumb, contentJson } = data;
      let params = {
        editor_type: 1, // 新编辑器
        content_json: JSON.stringify(contentJson),
        summary: text.slice(0, 100),
        title: text.slice(0, 20),
        type: 4,
        thumb: thumb.join(",")
      };

      apiContentEdit(params).then(res => {
        this.$message.success("发布成功");
        params = {
          ...params,
          game: this.gameInfo,
          game_alias: this.gameInfo.alias,
          game_id: this.gameInfo.id,
          like_count: 0,
          id: res.data.id,
          comment_count: 0,
          view_count: 0,
          user: this.loginUser,
          updated_at: 0
        };
        const insertIndex = this.modelsList[7].list.findLastIndex(item => {
          return item.is_top;
        });
        this.modelsList[7].list.splice(insertIndex + 1, 0, params);
        this.$refs.sendBox.onClear();
      });
    },

    onOkSq() {
      let params = {
        desc: this.sqDesc
      };
      if (!params.desc) {
        this.$message("请输入联系方式与申请理由！");
        return;
      }
      apiAdminApply(params).then(resres => {
        this.sqDesc = "";
        this.visibleSq = false;
      });
    },
    // 获取新版本弹窗
    getNewVersionInfos() {
      // 先获取上次弹窗时间，一天一次
      const localTime = localStorage.getItem("newVersionTime");
      if (localTime && typeof Number(localTime) === "number") {
        const isToday =
          this.$dayjs(Number(localTime) * 1000).format("YYYY-MM-DD") === this.$dayjs().format("YYYY-MM-DD");
        // 如果不是今天，就弹窗
        if (!isToday) {
          localStorage.setItem("newVersionTime", this.$dayjs().unix());
          this.showDialog();
        }
      } else {
        localStorage.setItem("newVersionTime", this.$dayjs().unix());
        this.showDialog();
      }
    },
    showDialog() {
      this.$confirm("噢！我尊贵的K站用户，恭喜您获得wiki新版本体验资格，要不要点击试一试？", "体验版", {
        confirmButtonText: "立即体验",
        cancelButtonText: "取消"
      }).then(() => {
        const { gameInfo } = this;
        const url = `http://${gameInfo.alias}.stage.${gameInfo.base_url}`;
        window.open(url, "_blank");
      });
    }
  }
};
</script>

<style lang="less" scoped>
.pc-home-container {
  // position: relative;
  .wiki-main-box {
    position: relative;
    width: 100%;
    margin-top: 12px;
    padding: 12px;
    border-radius: 12px;
    background: fade(#fff, 76%);
    // background: rgba(174, 181, 203, 0.45);
    .nav-box {
      width: 100%;
      margin-bottom: 12px;
      padding: 0 12px;
      box-sizing: border-box;
    }
  }
  .ba-wiki-main-box {
    margin-top: 0;
    border-radius: 0 0 12px 12px;
    padding: 0;
    .wiki-home {
      padding: 12px;
    }
  }
}

.wiki-home {
  min-height: 500px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

  .left-container {
    width: calc(100% - 308px);
    border-radius: 2px;
  }

  /deep/ .home-model-container {
    margin-bottom: 8px;
    background: #fff;

    .model-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      margin-bottom: 8px;
      background: #404040;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        border-left: 3px solid #f7ce7a;
        margin-left: 5px;
        padding-left: 11px;
        line-height: 20px;
        line-height: 20px;
      }

      .right-wrapper {
        .edit-btn {
          font-size: 12px;
          color: @basic;
        }
      }
    }
  }

  /deep/ .ba-model-container {
    .model-title {
      border-radius: 6px;
      .title {
        color: #3d4667;
        font-size: 16px;
        border-left: 3px solid #ffda35;
        margin-left: 8px;
        padding-left: 8px;
        line-height: 16px;
      }
    }
  }

  .right-container {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
}

.right-model-container {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 8px;

  .model-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background: #404040;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      border-left: 3px solid #f7ce7a;
      margin-left: 5px;
      padding-left: 11px;
      line-height: 20px;
      line-height: 20px;
    }

    .right-wrapper {
      padding-right: 12px;

      .edit-btn {
        font-size: 12px;
        color: @text-primary;
      }
    }
  }

  .right-model-content {
    padding: 12px 12px 0 12px;
  }
}

.game-detail-box {
  border-top: 6px solid #404040;

  .game-detail-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
  }

  .game-detail {
    margin: 8px 12px;

    .desc {
      font-size: 13px;
      color: #424242;
      line-height: 20px;
      overflow: hidden;
    }
  }
}
.ba-game-detail-box {
  border-color: #2fceff;
  background-image: url("~@/assets/ba_imgs/bg-icon1.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 150px auto;
}

.ba-right-container {
  .right-model-content {
    background: url("~@/assets/ba_imgs/bg-icon2.png") no-repeat top left / 90px auto;
  }
  .right-model-container .model-title {
    border-radius: 4px;
    .title {
      font-size: 16px;
      border-color: #ffda35;
      padding-left: 8px;
      margin-left: 8px;
    }
    .more-btn,
    .edit-btn {
      color: #666;
    }
  }
  .tag-color {
    transform: skewX(-15deg);
  }
}

.more-btn {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  .wiki-icon-arrow-right {
    display: inline-block;
    width: 5px;
    height: 8px;
    margin-left: 6px;
  }
  a {
    color: @text-basic;
  }
}

.sq-btn {
  color: #292929;
  cursor: pointer;
}

.entry-new-group {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + .item {
      margin-top: 10px;
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    > div {
      display: flex;
      align-items: center;
    }

    & + a {
      margin-top: 14px;
    }

    .date {
      color: #575757;
      font-size: 12px;
    }

    .title {
      .ellipsis;
      display: block;
      color: #424242;
      font-size: 13px;
      width: 220px;
      line-height: 20px;
    }

    .user {
      .ellipsis;
      width: 70px;
      color: @text-gray;
      font-size: 12px;
      margin-left: 20px;
      text-align: right;
    }
  }
}

.article-nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid @border-basic;
  margin-bottom: 20px;

  .nav {
    display: flex;
    align-items: center;

    div + div {
      margin-left: 20px;
    }

    div {
      color: #8b8b8b;
      padding: 4px 0;
      cursor: pointer;

      &.active {
        color: @basic;
      }
    }
  }

  .more-btn {
    color: #575757;
    transition: 0.3s;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: @basic;
    }
  }
}
.app-download-card {
  position: relative;
  border-top: 6px solid #404040;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 8px;
  flex-direction: row-reverse;
  gap: 15px;
  .download-qrcode {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    padding: 4px;
    border: 1px solid #45484c;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    flex-shrink: 0;
    width: 183px;
    padding-bottom: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #000;
      margin-bottom: 6px;
    }
    .desc {
      font-size: 14px;
      line-height: 20px;
      color: #424242;
    }
  }
  .card-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 0px 4px;
    background-color: rgba(0, 0, 0, 0.08);
    font-size: 12px;
    line-height: 16px;
    color: #515151;
    padding: 0 5px;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}

.ba-app-download-card {
  position: relative;
  border-top: none;
  background: url(~@/assets/images/ba-wiki-app-download-card.png) no-repeat;
  background-size: cover;
  background-size: 100%;
  width: 100%;
  height: 103px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  .download-qrcode {
    flex-shrink: 0;
    width: 84px;
    height: 84px;
    padding: 4px;
    border: 2px solid #ffdc24;
    border-radius: 6px;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    flex-shrink: 0;
    width: 183px;
    padding-top: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      margin-bottom: 6px;
    }
    .desc {
      font-size: 12px;
      line-height: 16px;
      color: #fff;
    }
  }
  .card-tag {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 4px;
    background-color: #fff;
    font-size: 12px;
    line-height: 16px;
    color: #515151;
    padding: 0 5px;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}
.ba-quyun-app-download-card {
  background: url(~@/assets/images/ba-quyun-app-download-card.png) no-repeat;
  background-size: 100%;
}

.link-btn {
  width: 100%;
  height: 56px;
  background: url("~@/assets/images/right-link-btn-default.png") no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  cursor: pointer;

  &.custom-img {
    color: #fff;
    text-shadow: 0 0 2px #999;
  }
}

.question-list {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  .item {
    position: relative;
    width: 274px;
    height: 36px;
    background: #efefef;
    border-radius: 2px;
    padding: 8px 24px 8px 12px;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .wiki-icon-arrow-right {
      position: absolute;
      top: 12px;
      right: 13px;
      display: inline-block;
      width: 5px;
      height: 8px;
    }
  }
}
</style>
