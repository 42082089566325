<template>
  <div :id="`menu-${item.id}`" class="home-model-container" :class="[alias + '-model-container']">
    <div :style="bgStyle('module_img')" class="model-title">
      <span class="title">{{ item.name }}</span>
    </div>
    <template>
      <model-tab v-for="(child, index) in list" :key="index" :list="child" />
    </template>
  </div>
</template>

<script>
import ModelTab from "./modelTab";
import { mapGetters } from "vuex";

export default {
  name: "homeModelContainer",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  model: {},
  components: { ModelTab },
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  methods: {
    bgStyle(filed, type = "cover") {
      let config = this.gameInfo.Config || {};
      if (config[filed]) {
        return {
          background: `url(${config[filed]})no-repeat left center`,
          backgroundSize: type
        };
      } else {
        return "";
      }
    },
    handleItem() {
      let { item } = this;
      this.list = [];
      let tab_num = -1;
      let arr = [];
      item.child = item.child ? item.child : [];
      item.child.forEach(child => {
        if (child.tab_num == tab_num) {
          arr.push(child);
        } else {
          if (arr.length) {
            this.list.push(arr);
            arr = [];
          }
          tab_num = child.tab_num;
          arr.push(child);
        }
      });
      if (arr.length > 0) {
        this.list.push(arr);
      }
    }
  },
  watch: {
    item: "handleItem"
  },
  created() {
    this.handleItem();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.ba-model-container {
  .model-title {
    border-radius: 6px;
    .title {
      color: #3d4667;
      font-size: 16px;
      border-left: 3px solid #ffda35;
      margin-left: 4px;
      padding-left: 4px;
      line-height: 16px;
    }
  }
}
</style>
