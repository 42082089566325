<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="617px"
    top="22vh"
    append-to-body
    center
    custom-class="login-dialog dialog-normal"
    @close="onClose"
    @open="onDialogOpen"
  >
    <div slot="title" class="login-title">
      <img class="login-head" src="@/assets/images/login-head.png" />
    </div>
    <div class="login-body">
      <div class="phone-login">
        <div class="login-type-title">手机登录</div>
        <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
          <el-form-item prop="phone">
            <el-input
              v-model="form.phone"
              maxlength="11"
              placeholder="请输入手机号"
              size="default"
              style="width: 290px;"
            >
              <template v-slot:prefix>
                <img class="login-input-icon" src="@/assets/images/login-phone.png" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="form.code" maxlength="6" placeholder="请输入验证码" size="default" style="width: 290px;">
              <el-button slot="suffix" :disabled="timeCount > 0" class="code-btn" type="text" @click="showImgCode">
                {{ codeText }}
              </el-button>
              <template v-slot:prefix>
                <img class="login-input-icon" src="@/assets/images/login-varfcode.png" />
              </template>
            </el-input>
            <div v-if="showVerifyCodeError" class="el-form-item__error">{{ verifyCodeErrorTxt }}</div>
          </el-form-item>
        </el-form>
        <template v-if="!isHeroGame">
          <el-divider>
            <span class="other-login-title">其他方式登录</span>
          </el-divider>
          <div class="other-login-box">
            <a class="qq-btn" @click="onQQWeChatLogin('qq')"></a>
            <!-- <a v-if="device.desktop" class="weixin-btn" @click="onQQWeChatLogin('wechat')"></a> -->
          </div>
        </template>
        <el-button class="login-submit" size="default" type="primary" @click="onLogin">
          登 录
        </el-button>
      </div>
      <div v-loading="wechatQRCodeLoading" class="wechat-login">
        <div class="login-type-title">微信登录</div>
        <div class="qrcode-container">
          <div id="__wechat_login_QRCode__" class="iframe-wrap"></div>
        </div>
      </div>
    </div>
    <div class="xy">
      <el-checkbox v-model="isChecked" />
      登录/扫码 表示您已阅读并同意
      <a class="" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">
        《Gamekee隐私政策》
      </a>
      和
      <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">
        《用户协议》
      </a>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="imgCodeVisible"
      :width="device.desktop ? '300px' : '75%'"
      append-to-body
      center
      custom-class="login-dialog dialog-verify"
      top="20vh"
    >
      <div slot="title" class="login-title">请输入图片验证码</div>
      <el-form ref="imgForm" :model="form" :rules="rules" class="login-form">
        <el-form-item>
          <el-row justify="space-between" type="flex">
            <el-col :span="14" class="img-box">
              <img :src="imgSrc" alt="" class="captcha-img" />
            </el-col>
            <el-col :span="8">
              <span class="reset-img-btn" @click="getCaptcha">换一张</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="img_code">
          <el-input v-model="form.img_code" maxlength="6" placeholder="请输入图形验证码" size="default" />
        </el-form-item>
      </el-form>

      <el-button class="W100" size="default" type="primary" @click="sendSmsCode">
        确定
      </el-button>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="protocolDialog"
      :show-close="false"
      width="340px"
      top="40vh"
      append-to-body
      center
      custom-class="login-dialog protocol-dialog"
    >
      <div style="color: #666666; line-height: 17px; font-size: 12px;">
        <span>登录即默认同意</span>
        <a class="protocol-txt" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">《Gamekee隐私政策》</a>
        <span>和</span>
        <a class="protocol-txt" :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">《用户协议》</a>
      </div>
      <template v-slot:title>
        <div style="text-align: left; font-size: 14px; font-weight: bold;">同意Gamekee协议</div>
      </template>
      <template v-slot:footer>
        <div class="footer">
          <div class="no" @click="protocolDialog = false">取消</div>
          <div class="yes" @click="handleAgreeProtocol">确认</div>
        </div>
      </template>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { apiPublicGetCaptcha, apiPublicSendSmsCode } from "@/api/publicApi";
import { apiQQAuthUrl, apiUserAuth, apiWechatAuthUrl } from "@/api/userApi";
// plugins
import eventBus from "@/plugins/eventBus";
import { setCookies, getCookies } from "../../../plugins/cookies";

export default {
  name: "login",
  mixins: [],
  props: {},
  model: {},
  components: {},
  data() {
    return {
      type: "normal",
      showVerifyCodeError: false,
      verifyCodeErrorTxt: "",
      visible: false,
      NODE_ENV: process.env.NODE_ENV,
      imgCodeVisible: false,
      protocolDialog: false,
      loading: false,
      isChecked: true,
      form: {
        phone: "",
        code: "",
        img_code: "",
        is_hero: this.isHeroGame ? 1 : 0
      },
      imgSrc: "",
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "手机号不正确",
            trigger: "blur"
          }
        ],
        img_code: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
        ]
      },
      codeText: "获取验证码",
      timeCount: 0,
      wechatQRCodeLoading: false,
      loginType: ""
    };
  },
  watch: {},
  beforeMount() {
    if (this.isHeroGame) {
      this.isChecked = true;
    }
    eventBus.$on("onShowLogin", this.onOpen);
  },
  methods: {
    // 展示登录弹窗
    onOpen(type) {
      this.visible = true;
      this.type = type;
      // 进行中
      sessionStorage.setItem("login_status", 0);
    },
    onClose() {
      this.visible = false;
      // 去除进行中
      sessionStorage.removeItem("login_status", 0);
      if (this.type === "commentLogin") {
        eventBus.$emit("onCommentLoginClose");
      }
      // 退出时需要
      const login_jump_path = sessionStorage.getItem("login_jump_path");
      if (login_jump_path) {
        sessionStorage.removeItem("login_jump_path");
      }
    },
    onQQWeChatLogin(type) {
      if (this.loading) return;
      if (!this.isChecked) {
        this.protocolDialog = true;
        this.loginType = type;
        return false;
      }

      let params = {
        state: window.location.href
      };
      this.loading = true;
      let typeApi = {
        qq: apiQQAuthUrl,
        wechat: apiWechatAuthUrl
      };
      typeApi[type](params)
        .then(res => {
          let cookie = "";
          // res.data.url.replace("redirect_uri=https", "redirect_uri=http")
          const win = window.open(res.data.url, "_blank", "width=600,height=400");
          win.moveTo(200, 285);
          const timer = setInterval(() => {
            if (win.closed) {
              clearInterval(timer);
              if (cookie) {
                // 登录完成
                sessionStorage.setItem("login_status", 1);
                location.reload();
              }
            }
          }, 50);
          const checkLogin = () => {
            const c = getCookies("userToken");
            if (c) {
              cookie = c;
              win.close();
            } else {
              setTimeout(checkLogin, 50);
            }
          };
          checkLogin();
        })
        .finally(_ => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },

    showImgCode() {
      if (this.timeCount > 0) return;
      this.$refs.loginForm.validateField(["phone"], err => {
        if (!err) {
          this.getCaptcha();
          this.imgCodeVisible = true;
        }
      });
      // this.imgCodeVisible = true;
    },
    // 获取短信验证码
    sendSmsCode() {
      let { form } = this;
      let isErr = false;
      this.$refs.loginForm.validateField(["phone", "img_code"], async err => {
        console.log(err);
        if (err) {
          isErr = true;
        }
      });
      if (isErr) return;
      this.imgCodeVisible = false;
      let params = {
        phone: form.phone,
        img_code: form.img_code,
        is_hero: this.isHeroGame ? 1 : 0
      };
      this.codeText = "60s" + "后重新获取";
      this.timeCount = 60;
      apiPublicSendSmsCode(params).then(res => {
        if (res.code == 0) {
          let timer = setInterval(() => {
            if (this.timeCount == 0) {
              this.codeText = "重新获取";
              clearInterval(timer);
              return;
            }
            this.timeCount--;
            this.codeText = this.timeCount + "后重新获取";
          }, 1000);
        }
      });
    },
    // 获取图形验证码
    getCaptcha() {
      apiPublicGetCaptcha({ is_hero: this.isHeroGame ? 1 : 0 }).then(res => {
        if (res.code == 0) {
          this.imgSrc = res.data.image_url;
        }
      });
    },
    loginTips() {
      return new Promise(resolve => {
        const loginTipsStatus = localStorage.getItem("loginTipsStatus");
        if (!loginTipsStatus) {
          this.$alert("打击整治网络水军，让网络空间正气充盈", "公告", {
            showClose: false,
            confirmButtonText: "确定",
            callback: action => {
              localStorage.setItem("loginTipsStatus", true);
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    },
    onLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return;
        if (!this.isChecked) {
          this.protocolDialog = true;
          return false;
        }
        try {
          const { code, data } = await apiUserAuth(this.form);
          if (code == 0) {
            this.$store.commit("set_userInfo", data.user);
            this.$store.commit("set_userToken", data.token);
            this.$store.commit("set_userUid", data.user.uid);

            setCookies("userToken", data.token);
            setCookies("userUid", data.user.uid);
            // 登录完成
            sessionStorage.setItem("login_status", 1);
            // 提示网络水军公告
            // await this.loginTips();
            window.location.reload();
          }
        } catch (error) {
          this.verifyCodeErrorTxt = error.msg;
          this.showVerifyCodeError = true;
          setTimeout(() => {
            this.verifyCodeErrorTxt = "";
            this.showVerifyCodeError = false;
          }, 3000);
        }
      });
    },
    onDialogOpen() {
      this.initWechatQRCode();
    },
    handleAgreeProtocol() {
      this.isChecked = true;
      this.protocolDialog = false;
      if (this.loginType === "") {
        this.onLogin();
      } else {
        this.onQQWeChatLogin(this.loginType);
      }
    },
    async initWechatQRCode() {
      this.wechatQRCodeLoading = true;
      const domEl = () => {
        return new Promise(resolve => {
          const query = () => {
            const el = document.getElementById("__wechat_login_QRCode__");
            if (!el) {
              setTimeout(query, 10);
            } else {
              resolve();
            }
          };
          query();
        });
      };
      await domEl();
      // eslint-disable-next-line no-new , no-undef
      new WxLogin({
        self_redirect: false,
        id: "__wechat_login_QRCode__",
        appid: "wxac6e20a64a95aa01",
        scope: "snsapi_login",
        redirect_uri: encodeURI("https://www.gamekee.com/callback"),
        state: window.location.href,
        stylelite: 1
      });
      setTimeout(() => {
        this.wechatQRCodeLoading = false;
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
.login-title {
  font-size: 18px;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/ .login-body {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  margin: 0 2px 35px 2px;
  .login-type-title {
    text-align: center;
    margin-bottom: 14px;
    line-height: 20px;
    color: #000;
  }
  .phone-login {
    padding: 18px 28px;
    width: 290px;
    border-right: 1px solid #e2e2e2;
    box-sizing: content-box;
    .el-form-item {
      margin-bottom: 14px;
    }
    .el-input__inner {
      background-color: #f7f7f7;
      border-color: transparent;
      border-radius: 8px;
    }
    .el-input__inner::-webkit-input-placeholder {
      font-size: 12px;
      color: #c2c2c2;
    }
    .el-divider--horizontal {
      margin-top: 24px;
    }
    .el-input__inner::-moz-placeholder {
      font-size: 12px;
      color: #c2c2c2;
    }
    .login-input-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .login-submit {
      width: 100%;
      border-radius: 100px;
      background-color: #2fceff;
      border: none;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
  }
  .wechat-login {
    padding-top: 18px;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-container {
      position: relative;
      width: 174px;
      height: 174px;
      background-color: #f7f7f7;
      .iframe-wrap {
        height: 160px;
        width: 160px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        iframe {
          position: absolute;
          left: -68px;
        }
      }
    }
  }
}
.img-box {
  display: flex;
  height: auto;
  height: 40px;
}

.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  color: #2fceff;
  right: 7px;
  &:hover {
    color: #2fceff;
  }
}

.other-login-title {
  color: fade(@text-basic, 50%);
  font-weight: 400;
}

.other-login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 25px;

  a {
    display: flex;
    width: 48px;
    height: 48px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #e8e8e8;

    &.qq-btn {
      background: url("./../../../assets/images/login-qq.png") no-repeat center #f7f7f7;
      background-size: 24px 24px;
    }

    &.weixin-btn {
      background: url("./../../../assets/images/login-wechat.png") no-repeat center #f7f7f7;
      background-size: 24px 24px;
    }
  }
}

/deep/ .xy {
  font-size: 11px;
  line-height: 16px;
  color: @gray;
  cursor: pointer;
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  .el-checkbox__inner,
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5a75a5;
    border-color: #5a75a5;
    border-radius: 50%;
  }
  a {
    color: #5a75a5;
  }
}
</style>

<style lang="less" scoped>
/deep/ .dialog-normal {
  .login-title {
    position: absolute;
    z-index: 1;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
  }
  border-radius: 20px;
  background: linear-gradient(180deg, #fdffff 0%, #bdefff 100%);
  .el-dialog__header {
    padding: 22px 0;
  }
  .el-dialog__body {
    padding-top: 9px;
    background: url("../../../assets/images/login-bg-bottom.png") no-repeat;
    background-position: right bottom;
  }
  .el-dialog__headerbtn {
    background-image: url("./../../../assets/images/login-close.png");
    width: 36px;
    height: 36px;
    z-index: 1;
    background-size: contain;
    top: -12px;
    right: -12px;
    cursor: pointer;
    transition: transform 300ms;
    &:hover {
      transform: rotate(90deg);
    }
    .el-dialog__close {
      display: none;
    }
  }
}
/deep/ .dialog-verify {
  .login-title {
    position: static;
    transform: translateX(0);
  }
}
/deep/ .protocol-dialog {
  .footer {
    display: flex;
    justify-content: space-between;
    .no,
    .yes {
      width: 45%;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .no {
      color: #2fceff;
      background-color: #fff;
      border: 1px solid #2fceff;
    }
    .yes {
      color: #fff;
      background-color: #2fceff;
    }
  }
  .protocol-txt {
    color: #5a75a5;
    &:active {
      color: #5a75a5;
    }
  }
}
</style>
