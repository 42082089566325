<template>
  <div class="notice-link-group">
    <template v-for="(item, index) in list">
      <router-link
        v-if="all || (!all && index < 3)"
        :key="index"
        :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.id, true)"
        class="article-row-tag"
      >
        <template v-if="lang == 'en'">
          <span :class="['span-point', `span-point-${tagList[item.tag || 0].color || 'red'}`]"></span>
        </template>
        <template v-else>
          <span :class="['tag-color', `tag-color-${tagList[item.tag || 0].color || 'red'}`]">
            {{ tagList[item.tag || 0].text }}
          </span>
        </template>
        <span class="title">{{ item.title }}</span>
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getLang } from "@/i18n";
import { getJumpWikiDetailUrl } from "@/plugins/util";

function getTagList($vm) {
  // console.log($vm);
  return {
    0: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    1: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    2: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    3: {
      color: "orange",
      text: $vm.$t("notice_tags.tag2")
    },
    4: {
      color: "green",
      text: $vm.$t("notice_tags.tag1")
    }
  };
}

export default {
  name: "noticeListGroup",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    all: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {},
  data() {
    return {
      lang: "zh-cn"
    };
  },
  computed: {
    ...mapGetters(["gameInfo"]),
    tagList() {
      return getTagList(this);
    }
  },

  watch: {},
  created() {},
  beforeMount() {
    this.lang = getLang();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    getJumpWikiDetailUrl
  }
};
</script>

<style lang="less" scoped>
.notice-link-group {
  .article-row-tag {
    padding: 0 8px;
    height: 32px;
    background: #f9f9f9;
  }
}
</style>
