import Vue from "vue";
import { createApp } from "./app";
import { checkIsWww } from "./plugins/util";
import { setCookies, getCookies } from "./plugins/cookies";
import { getDevice } from "./plugins/device";
// api
import { apiGameInfo } from "@/api/gameApi";

// import { datafluxRum } from '@cloudcare/browser-rum';

// datafluxRum.init({
//   applicationId: 'gamekee',
//   datakitOrigin: 'https://datakitfront.yingxiong.com/', // 协议（包括：//），域名（或IP地址）[和端口号]
//   env: process.env.NODE_ENV,
//   version: '1.0.0',
//   trackInteractions: true,
//   traceType: 'ddtrace' // 非必填，默认为ddtrace，目前支持 ddtrace、zipkin、skywalking_v3、jaeger、zipkin_single_header、w3c_traceparent 6种类型
//   // allowedTracingOrigins: ['https://api.example.com', 'https://.*.my-api-domain.com/'] // 非必填，允许注入trace采集器所需header头部的所有请求列表。可以是请求的origin，也可以是是正则
// });

const isWww = checkIsWww(window.location.hostname);

// 创建
const { app, router, store } = createApp({ isWww });

store.commit("set_isWww", isWww);
// 设置device信息
const device = getDevice(window.navigator.userAgent);
store.commit("set_device", device);

// 客户端状态激活
if (window.__INITIAL_STATE__) {
  // console.log("__INITIAL_STATE__", window.__INITIAL_STATE__);
  store.replaceState(window.__INITIAL_STATE__);

  // 获取到登录信息，并设置到cookies
  const userToken = window.__INITIAL_STATE__.userToken;
  const userUid = window.__INITIAL_STATE__.userUid;
  if (userToken && userUid) {
    setCookies("userToken", userToken);
    setCookies("userUid", userUid);
  }
} else {
  // 判断cookie中是否有登录信息
  const userToken = getCookies("userToken");
  const userUid = getCookies("userUid");
  if (userToken && userUid) {
    store.commit("set_userToken", userToken);
    store.commit("set_userUid", userUid);
  }
}

// 传递vuex store引用
Vue.__VUEX_STORE__ = store;

// 需要在挂载 app 之前调用 router.onReady，
// 因为路由器必须要提前解析路由配置中的异步组件，才能正确地调用组件中可能存在的路由钩子
router.onReady(async () => {
  const currentRoute = router.currentRoute;
  try {
    // 匹配wiki路由
    // 重新设置alias gameInfo
    const routeMeta = currentRoute.meta;
    if (routeMeta.wikiRoute) {
      const alias = currentRoute.params.gameAlias;
      store.commit("set_alias", alias);
      store.commit("set_isWww", false);
    } else {
      store.commit("set_alias", "www");
      store.commit("set_isWww", true);
    }
    const { code, data } = await apiGameInfo({ t: Date.now() });
    if (code === 0) {
      const gameInfo = data;
      const alias = gameInfo.alias;
      // 埋点
      window.staticFn.setCustomConfig({ game_id: gameInfo.id });
      const isHasWiki = gameInfo.wiki_created_at != 0;
      store.commit("set_alias", alias);
      store.commit("set_isHasWiki", isHasWiki);
      store.commit("set_gameInfo", gameInfo);
    }
    // 渲染
    app.$mount("#app");
  } catch (err) {
    if (err && err.code == 403 && err.msg == "缺少游戏信息") {
      // 没有游戏信息，重定向到404
      currentRoute.replace("/404");
    }
  }
});
