<template>
  <div :class="['top-nav-container', scrollY > 120 ? 'fixed-top' : '', alias + '-top-nav-container']">
    <div class="top-nav-body">
      <div class="l-wrapper">
        <el-tooltip v-for="(item, index) in entryList" :content="item.name" :key="index">
          <span @click="goMenu(item)">{{ item.name }}</span>
        </el-tooltip>
      </div>
      <!-- <div class="r-wrapper">
        <div class="search-box">
          <input
            v-model="searchName"
            :placeholder="$t('search_within_the_wiki')"
            type="text"
            @focus="isLogin"
            @keyup.enter="goSearch"
          />
          <i class="iconfont icon-sousuo" @click="goSearch" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "topNavContainer",
  mixins: [],
  props: {
    entryList: {
      type: Array,
      detault: () => []
    }
  },
  model: {},
  components: {},
  data() {
    return {
      searchName: "",
      scrollY: 0
    };
  },
  computed: {},

  watch: {},
  created() {},
  mounted() {
    window.addEventListener("scroll", this.winScroll);
  },
  updated() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.winScroll);
  },
  destroyed() {},
  methods: {
    goMenu(item) {
      let top = document.getElementById("menu-" + item.id).offsetTop;
      window.scrollTo({
        top: top,
        behavior: "smooth"
      });
    },
    goSearch() {
      let { searchName } = this;
      if (searchName) {
        this.searchName = "";
        this.$router.push(`list?kw=${searchName}`);
      }
    },
    winScroll(e) {
      this.scrollY = window.document.documentElement.scrollTop || window.document.body.scrollTop;
    }
  }
};
</script>

<style lang="less" scoped>
.top-nav-container {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #404040;
  margin-bottom: 12px;
  &.ba-top-nav-container {
    background: #fff;
    border-bottom: none;
  }
  &.fixed-top {
    .top-nav-body {
      width: 1200px;
      position: fixed;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background: rgba(255, 255, 255, 0.76);
    }
  }

  .top-nav-body {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .l-wrapper {
    font-size: 14px;
    color: #323232;
    width: calc(100% - 160px);

    span {
      .ellipsis;
      padding: 0 8px;
      color: #323232;
      cursor: pointer;
      display: inline-block;
      max-width: 86px;

      &:hover {
        color: #000;
      }
    }
  }

  .r-wrapper {
    width: 160px;
  }

  .search-box {
    width: 140px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid fade(#555555, 64);
    position: relative;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      padding-right: 30px;
      padding-left: 10px;
      font-size: 12px;
    }

    .iconfont {
      color: fade(#555555, 64);
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
