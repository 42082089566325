// import './esdk-obs-browserjs-3.19.5.min.js';
// import $ from 'jquery';
// import ObsClient from './esdk-obs-browserjs-3.19.5.min.js';
// import store from '@/store/index';
import Vue from "vue";
import dayjs from "dayjs";
import { apiGetObsClientSecurityToken } from "@/api/publicApi";
import { getCookies, setCookies } from "./cookies";

// require('./esdk-obs-browserjs-3.19.5.min.js');
/**
 *
 * @param {*} params
 * let params = {
 * file:file
 * accept:'png,gif',  //多个用,隔开
 * maxSize:'1024', 单位kb
 * progressCb:function(percent,{transferredAmount, totalAmount, totalSeconds}){  //进度回调
 *
 *  }
 * }
 * uploadImg(params)
 *      .then(res => {
 *         console.log(res)
 *       })
 *       .catch(err => {
 *         console.log(err)
 *       })
 */
// 上传文件
async function uploadFile(params) {
  const store = Vue.__VUEX_STORE__;
  let obsClinetParams = getCookies("obs_client_params") ? JSON.parse(getCookies("obs_client_params")) : null;
  if (!obsClinetParams) {
    try {
      const res = await apiGetObsClientSecurityToken();
      if (res && res.code == 0) {
        const { access, secret, security_token, bucket_name, end_point } = res.data;
        obsClinetParams = {
          access_key_id: access,
          secret_access_key: secret,
          security_token: security_token,
          server: end_point,
          bucket_name: bucket_name
        };
        setCookies("obs_client_params", JSON.stringify(obsClinetParams), {
          expires: new Date(Date.now() + 60 * 60 * 1000)
        });
      } else {
        return Promise.reject("上传token获取异常");
      }
    } catch (error) {
      return Promise.reject("上传token获取异常");
    }
  }
  return new Promise((resolve, reject) => {
    params.obsClientData = {
      // access_key_id: 'C8TAVPQ63LM4EY5AGXWL',
      // secret_access_key: 'bUlfIu1AQA1U9RJlxMMd70bzjTE02C3WazpCdlEq',
      // server: 'obs.cn-north-1.myhuaweicloud.com'
      access_key_id: obsClinetParams.access_key_id,
      secret_access_key: obsClinetParams.secret_access_key,
      security_token: obsClinetParams.security_token,
      server: obsClinetParams.server
    };
    params.bucketName = obsClinetParams.bucket_name || "wiki-obs";
    if (!params.file) {
      let errMsg = {
        msg: "请选择文件"
      };
      reject(errMsg);
      return false;
    }
    if (params.accept && !params.accept.includes(fnGetExtension(params.file)[1])) {
      let errMsg = {
        msg: `请上传符合以下格式的文件：${params.accept}`
      };
      reject(errMsg);
      return false;
    }
    if (params.maxSize && params.file.size / 1024 > params.maxSize) {
      let errMsg = {
        msg: `请上传大小在${params.maxSize}kb内的文件`
      };
      reject(errMsg);
      return false;
    }
    console.log(params.file.size);
    params.obsClient = new ObsClient(params.obsClientData);
    addWaterMark(params.file, function(_file) {
      params.obsClient.putObject(
        {
          Bucket: params.bucketName,
          Key: params.objectKey,
          SourceFile: _file,
          ProgressCallback: callback
        },
        function(err, result) {
          if (!err && result.CommonMsg.Status < 300) {
            result.key = params.objectKey;
            result.url = "//" + store.getters.gameInfo.cdn_base_url + "/" + params.objectKey;
            console.log("上传成功", result);
            resolve(result);
          } else {
            let msg = err || result.CommonMsg.Code;
            // 回调错误
            reject(msg);
          }
        }
      );
    });

    //  添加全局水印
    function addWaterMark(file, cb) {
      console.log("params---");
      console.log(params);
      const heroGame = false;
      if (params.fileType == "audio") {
        params.objectKey = createFileKey(params);
        cb && cb(file);
        return false;
      }
      if (params.fileType == "live2d") {
        params.objectKey = createFileKey(params);
        cb && cb(file);
        return false;
      }

      // console.log(params);
      var url = window.URL.createObjectURL(file);
      // var $img = $('<img />');
      var $img = document.createElement("img");

      $img.setAttribute("crossOrigin", "Anonymous");
      $img.setAttribute("src", url);

      $img.onload = function() {
        var w = $(this)[0].naturalWidth;
        var h = $(this)[0].naturalHeight;
        params.objectKey = createFileKey(params, w, h);

        if (heroGame || !params.waterMark || params.waterMark <= 1) {
          cb && cb(file);
          return false;
        }
        if (params.file.type == "image/gif") {
          cb && cb(file);
          return false;
        }

        if ((h < 200 || w < 200) && params.waterMark != 3) {
          cb && cb(params.file);
          return false;
        }
        var canvas = $("<canvas></canvas>")[0];
        canvas.setAttribute("crossOrigin", "Anonymous");
        var ctx = canvas.getContext("2d");
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage($img, 0, 0, w, h);
        var $waterMark = new Image();
        $waterMark.setAttribute("crossOrigin", "Anonymous");
        if (params.waterMark == 1) {
          // $waterMark.src = '//img01.gamekee.com/M00/0F/92/ChpCl1y9naOEcpfMAAAAAIakyaA213.png';
          $waterMark.src = "//cdnimg01.gamekee.com/M00/14/D4/ChpCl138b0-EYRyvAAAAAB_Hh-4423.png";
        } else if (params.waterMark == 2) {
          $waterMark.src = "//cdnimg.gamekee.com/images/www/1623222922268_76430761.png";
          if (window.location.href.indexOf("yingxiong") > -1 || window.location.href.indexOf("wiki.s-game") > -1) {
            $waterMark.src = "//cdnimg01.gamekee.com/M00/18/F8/ChpCl2AzdC2EJfdeAAAAAM9ClJQ219.png";
          }
        } else if (params.waterMark == 3) {
          $waterMark.src = "//cdnimg.gamekee.com/images/www/1614248230839_29642433.png";
          if (window.location.href.indexOf("yingxiong") > -1 || window.location.href.indexOf("wiki.s-game") > -1) {
            $waterMark.src = "//cdnimg01.gamekee.com/M00/18/F8/ChpCl2AzdC2EJfdeAAAAAM9ClJQ219.png";
          }
        }

        $waterMark.onload = function() {
          if (params.waterMark == 2) {
            // 循环生成水印图片
            var pat = ctx.createPattern($waterMark, "repeat");
            ctx.fillStyle = pat;
            ctx.fillRect(0, 0, w, h);
          } else if (params.waterMark == 3) {
            ctx.fillStyle = ctx.createPattern($waterMark, "repeat");
            ctx.fillRect(0, 0, w, h);
          } else if (params.waterMark == 1) {
            var w_h = $waterMark.naturalHeight;
            var w_w = $waterMark.naturalWidth;
            ctx.drawImage($waterMark, w - w_w - 20, h - w_h - 20);
          }
          try {
            var dataURL = canvas.toDataURL(params.file.type);
            // console.log('url', dataURL);
            cb && cb(dataURLtoFile(dataURL, params.file.name));
          } catch (e) {
            cb && cb(params.file);
          }
        };
      };
    }

    // 创建文件key;
    function createFileKey(params, w = 0, h = 0) {
      const store = Vue.__VUEX_STORE__;
      let key = "";
      if (params.fileType == "live2d") {
        key = `wiki2.0/live2d/`;
      } else if (params.is_video) {
        key = `wiki2.0/video/`;
      } else {
        key = `wiki2.0/images/w_${w}/h_${h}/`;
      }
      key += store.getters.gameInfo.id + "/";
      key += (getCookies("userUid") || 0) + "/";
      key += dayjs().year() + "/";
      key += dayjs().month() + "/";
      key += dayjs().date() + "/";

      if (params.fileType == "live2d") {
        key += params.file.name;
      } else {
        key += Number.parseInt(Math.random() * 1000000);
        key += fnGetExtension(params.file)[0];
      }

      if (params.key) {
        params.objectKey = params.key;
      }

      return key;
    }

    function callback(transferredAmount, totalAmount, totalSeconds) {
      // console.log(transferredAmount, totalAmount, totalSeconds);
      if (transferredAmount === totalAmount) {
        // console.log(`进度-size(${transferredAmount}-${totalAmount})`, new Date().getTime());
      }
      let percent = parseInt((transferredAmount * 100.0) / totalAmount);
      params.progressCb &&
        params.progressCb(percent, {
          transferredAmount,
          totalAmount,
          totalSeconds
        });
    }
  });
}

function fnGetExtension(file) {
  var fileName = file.name;
  // eslint-disable-next-line
  var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var fileExtension = fileName.match(patternFileExtension);
  return fileExtension;
}

// base64转file;
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

export { uploadFile };
