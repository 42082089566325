<template>
  <div :class="['calendar-container', classBase + 'box', alias + '-calendar-container']">
    <div class="calendar-title">
      <span v-if="isMonth" @click="onChangeMonth(-1)"
        ><i class="icon el-icon-arrow-left" /> {{ $t("prev_month") }}</span
      >
      <span v-else @click="onChangeWeek(-1)"><i class="icon el-icon-arrow-left" /> {{ $t("prev_week") }}</span>
      <div class="date">
        {{ dateObj.years }}{{ $t("year") }}{{ dateObj.months ? dateObj.months + 1 : "" }}{{ $t("mouth") }}
      </div>
      <span v-if="isMonth" @click="onChangeMonth(1)">{{ $t("next_month") }}<i class="icon el-icon-arrow-right"/></span>
      <span v-else @click="onChangeWeek(1)">{{ $t("next_week") }}<i class="icon el-icon-arrow-right"/></span>
    </div>
    <div class="calendar-content">
      <div class="weeks-title">
        <div v-for="item in weeks" :key="item">{{ item }}</div>
      </div>
      <div v-show="calendarList && calendarList.length" class="days-box">
        <template>
          <div
            v-for="(row, index) in calendarList.length / 7"
            v-show="isMonth || (index == activeWeek && !isMonth)"
            :key="row"
            class="week-row"
          >
            <template>
              <div
                v-for="(week, index2) in 7"
                :key="week"
                :class="[
                  'day-item',
                  activeDay == calendarList[index * 7 + index2].day && calendarList[index * 7 + index2].inMonth
                    ? 'active'
                    : '',
                  calendarList[index * 7 + index2].inMonth ? '' : 'gray'
                ]"
                @click="onChooseDay(calendarList[index * 7 + index2])"
              >
                <div>
                  {{ calendarList[index * 7 + index2].day }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="toggle-btn" @click="onTooggleMonth">{{ isMonth ? $t("toggle_week") : $t("toggle_month") }}</div>
    <div class="tag-group">
      <template>
        <div
          v-for="item in filterPubAreaList"
          @click="onFilterList(item)"
          :class="activeArea == item ? 'active' : ''"
          :key="item"
        >
          {{ item }}
        </div>
      </template>
    </div>
    <div class="item-list">
      <template>
        <div
          v-for="item in filterActiveList"
          :key="item.id"
          :class="['item', item.importance ? 'border-red' : 'border-green']"
        >
          <div class="info-box">
            <a
              :class="['item-title', item.link_url ? 'link' : '']"
              :href="item.link_url || 'javascript:;'"
              :target="item.link_url ? '_blank' : ''"
              >{{ item.title }}</a
            >
            <div class="date-row">
              <span>{{ item.time }}</span>
              <span class="item-toggle-btn" @click="onToggleItem(item)">{{
                item.isShow ? $t("calendar_toggle1") : $t("calendar_toggle2")
              }}</span>
            </div>
            <div v-if="item.isEnd" class="tag">{{ $t("calendar_end") }}</div>
          </div>
          <div v-show="item.isShow" class="desc-box">
            <img v-if="item.picture" :src="item.picture" alt="" />
            <div class="desc" v-html="xss(item.description)"></div>
            <div v-if="item.count_down" class="count_down" v-html="xss(item.count_down_time)"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { apiCalendarQuery } from "@/api/calendarApi";

export default {
  name: "calendarContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {},
  data() {
    return {
      weeks: [
        this.$t("weeks.mon"),
        this.$t("weeks.tue"),
        this.$t("weeks.wed"),
        this.$t("weeks.thu"),
        this.$t("weeks.fri"),
        this.$t("weeks.sat"),
        this.$t("weeks.sun")
      ],
      calendarList: [],
      dateObj: {},
      daysInMonth: 0,
      activeWeek: 0,
      activeDay: 0,
      // 是否已月份展示
      isMonth: false,
      activityList: [],
      activeArea: "全部"
    };
  },
  computed: {
    filterPubAreaList() {
      // console.log(123, this.activityList)
      let arr = this.activityList && this.activityList.map(item => item.pub_area).filter(item => item);
      arr = arr ? [...new Set(arr), "全部"] : ["全部"];
      // eslint-disable-next-line
      this.activeArea = arr[0];
      return arr;
    },
    filterActiveList() {
      if (this.activeArea === "全部") return this.activityList;
      return this.activityList.filter(item => item.pub_area.includes(this.activeArea));
    }
  },
  methods: {
    init() {
      this.dateObj = this.$dayjs().toObject();
      this.daysInMonth = this.$dayjs().daysInMonth();
      this.activeDay = this.dateObj.date;
      this.generateCalendar();
      this.getActivityInDay();
      if (!this.list.length) return;
      this.handleList(this.list);
    },
    // 按月生成日历
    generateCalendar() {
      let { dateObj, daysInMonth } = this;
      // 每月第一天
      let firstDays = this.$dayjs(`${dateObj.years}-${dateObj.months + 1}-1`);
      let lastDays = this.$dayjs(`${dateObj.years}-${dateObj.months + 1}-${daysInMonth}`);
      let firstDaysOfWeek = firstDays.day() || 7;
      let lastDaysOfWeek = lastDays.day();
      let calendarList = [];
      // console.log({ firstDaysOfWeek });
      // 补全当月第一天前的日期
      for (let i = 1; i < firstDaysOfWeek; i++) {
        let date = firstDays.subtract(i, "day");
        let obj = {
          day: date.date(),
          // 是否在选中月份内
          inMonth: false,
          list: []
        };
        calendarList.unshift(obj);
      }
      for (let i = 1; i <= daysInMonth; i++) {
        let obj = {
          day: i,
          // 是否在选中月份内
          inMonth: true,
          list: []
        };
        calendarList.push(obj);
      }
      // 补全当月最后一天的日期
      // 日历最后一天为周末导致计算的日历没了，注释掉
      // if (lastDaysOfWeek == 0) return;
      for (let i = 1; i <= 7 - lastDaysOfWeek; i++) {
        let date = lastDays.add(i, "day");
        let obj = {
          day: date.date(),
          // 是否在选中月份内
          inMonth: false,
          list: []
        };
        calendarList.push(obj);
      }

      this.calendarList = calendarList;
    },
    // 处理原始活动列表
    handleList(list) {
      list.forEach(item => {
        // 是否展开
        item.isShow = false;
        // 是否已结束
        item.isEnd = false;
        // 活动持续时间
        item.time = this.$dayjs(item.begin_at * 1000).format("MM-DD HH:mm") + " ~ ";
        item.time += this.$dayjs(item.end_at * 1000).format("MM-DD HH:mm");

        if (item.importance) item.isShow = true;
        if (this.$dayjs().unix() > item.end_at) {
          item.isEnd = true;
        }
        item.count_down_time = "";
        // 倒计时
        if (item.count_down) {
          item.timer = setInterval(() => {
            const duration = item.end_at * 1000 - this.$dayjs();
            if (duration <= 0) {
              clearInterval(item.timer);
              item.count_down_time = `${this.$t("time_left")}<span>${this.$t("calendar_end")}</span>`;
              return;
            }
            const diffTime = this.$dayjs.duration(duration);
            const day = Math.floor(duration / 1000 / 60 / 60 / 24);
            // console.log(day);
            const hours = diffTime.hours();
            const minutes = diffTime.minutes();
            const seconds = diffTime.seconds();
            item.count_down_time = `${this.$t("time_left")}${this.$t("time_left2", {
              day,
              hours,
              minutes,
              seconds
            })}`;
            this.$forceUpdate();
          }, 1000);
        }
      });
      this.handleCalendarList(list);
    },
    // 按天处理当月内活动
    handleCalendarList(list) {
      this.calendarList.forEach(_item => {
        if (!_item.inMonth) return;
        list.forEach(item => {
          const day_s = `${this.dateObj.years}-${this.dateObj.months + 1}-${_item.day} 00:00:00`;
          const day_e = `${this.dateObj.years}-${this.dateObj.months + 1}-${_item.day} 23:59:59`;
          const dayTime_s = this.$dayjs(day_s).unix();
          const dayTime_e = this.$dayjs(day_e).unix();
          const inActive = item.begin_at <= dayTime_e && dayTime_s <= item.end_at;

          if (inActive) {
            _item.list.push(item);
          }
        });
      });
    },
    // 切换月份，周展示
    onTooggleMonth() {
      this.isMonth = !this.isMonth;
    },
    // 切换周
    onChangeWeek(num) {
      this.activeArea = "全部";
      this.activeWeek += num;
      this.activeDay = this.activeWeek * 7 - 1;
      this.activityList = [];
      if (this.activeWeek >= this.calendarList.length / 7 || this.activeWeek < 0) {
        this.activeDay = 1;
        this.generateCalendar();
        this.activeWeek = num > 0 ? 0 : this.calendarList.length / 7 - 1;
        this.onChangeMonth(num);
      }
      this.getActivityInDay();
    },
    // 切换月份
    async onChangeMonth(num, clearDay = false) {
      this.activeArea = "全部";
      let { dateObj } = this;
      let day = `${dateObj.years}-${dateObj.months + 1 + num}-1`;
      this.dateObj = this.$dayjs(day).toObject();
      this.daysInMonth = this.$dayjs(day).daysInMonth();
      this.activityList = [];
      this.generateCalendar();
      if (clearDay) {
        this.activeDay = 0;
        if (this.isMonth) {
          this.activeWeek = 0;
        }
      }
      let params = {
        active_at: this.$dayjs(day).unix()
      };
      await apiCalendarQuery(params).then(res => {
        this.handleList(res.data);
      });
      this.getActivityInDay();
    },
    // 选择日期
    async onChooseDay(item) {
      if (item.day == this.activeDay && item.inMonth) return;
      this.activeDay = item.day;
      this.getActivityInDay();
      // 不是当月日期，直接切换月份，并保留选择日期；
      if (!item.inMonth) {
        await this.onChangeMonth(item.day < 7 ? 1 : -1, false);
        this.getActivityInDay();
      }
    },
    // 获取选中日期的活动
    getActivityInDay() {
      let { activeDay, calendarList } = this;
      calendarList.forEach((item, index) => {
        if (item.day == activeDay && item.inMonth) {
          this.activityList = item.list;
          this.activeWeek = parseInt(index / 7);
        }
      });
    },
    //
    onToggleItem(item) {
      item.isShow = !item.isShow;
      this.$forceUpdate();
    },
    onFilterList(item) {
      if (item === this.activeArea) return;
      this.activeArea = item;
    }
  },
  watch: {
    // list: 'onChangeList'
  },
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.calendar-container {
  .calendar-content {
    margin-top: 8px;
    background: #efeff0;
    border-radius: 2px;
    padding: 12px;
  }

  .calendar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-basic;
    user-select: none;
    line-height: 1;

    div {
      width: 40%;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      font-size: 12px;
      padding: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 14px;
    }
  }

  .weeks-title {
    display: flex;
    align-items: stretch;
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 1;
    justify-content: space-between;

    div {
      flex: 1;
      text-align: center;
    }
  }

  .days-box {
    margin-top: 8px;

    .week-row {
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .day-item {
      width: 28px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      > div {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #a8a8a8;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
        position: relative;
        border-radius: 2px;
      }

      &:hover {
        > div {
          background: #ddd;
        }
      }

      &.active {
        > div {
          background: #404040;
          color: @text-primary;

          &:after {
            content: "";
            position: absolute;
            width: 24px;
            height: 24px;
            top: 2px;
            left: 2px;
            box-sizing: border-box;
            border: 0.5px solid @text-primary;
            border-radius: 2px;
          }
        }
      }

      &.gray {
        > div {
          color: #b8b8b8;
          background: fade(#a8a8a8, 21);
        }
      }
    }
  }

  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9a9a9a;
    padding: 10px 0;
    font-size: 12px;
    margin: 0 6px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #b8b8b8;
  }

  .tag-group {
    display: flex;
    align-items: center;
    color: #b8b8b8;
    font-size: 14px;
    position: relative;

    > div {
      padding: 8px 6px;
      cursor: pointer;
      position: relative;

      & + div:before {
        content: "";
        width: 1px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #d8d8d8;
      }

      &.active {
        color: #171717;
      }
    }
  }

  .item-list {
    .item {
      border-radius: 2px;
      background: #f7fafb;
      border-left: 3px solid @text-gray;

      &.border-red {
        border-color: @red;
      }

      &.border-green {
        border-color: #4acf75;
      }

      & + .item {
        margin-top: 14px;
      }

      .info-box {
        height: 60px;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .tag {
          position: absolute;
          top: 0;
          right: 0;
          background: #797979;
          border-radius: 0 0 0 6px;
          font-size: 12px;
          color: #fff;
          padding: 2px 4px;
        }

        .item-title {
          .ellipsis;
          width: 80%;
          font-size: 13px;
          font-weight: bold;
          color: @text-basic;
          display: block;

          &.link:hover {
            text-decoration: underline;
          }
        }

        .date-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: #424242;

          .item-toggle-btn {
            color: #bbb;
            cursor: pointer;
            user-select: none;
          }
        }
      }

      .desc-box {
        padding: 8px 12px;

        img {
          width: 100%;
          border-radius: 4px;
        }

        .desc {
          font-size: 12px;
          color: #424242;
        }
      }

      .count_down {
        margin-top: 10px;
        font-weight: 500;
        color: @text-basic;

        /deep/ span {
          color: @red;
          padding: 0 2px;
        }
      }
    }
  }
}
.ba-calendar-container {
  .days-box .day-item.active {
    > div {
      background: #f4ea4d;
      color: #582a18;
      &:after {
        border-color: #302622;
        opacity: 0.6;
      }
    }
  }
  .item-list .item {
    background-image: url("~@/assets/ba_imgs/bg-icon1.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 120px auto;
  }
}
.wap-box {
  .days-box .day-item {
    width: 36px;
    height: 36px;
    margin-top: 12px;

    &.active > div:after {
      width: 32px;
      height: 32px;
    }

    > div {
      width: 36px;
      height: 36px;
    }
  }
}
</style>
