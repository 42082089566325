<template>
  <div id="app" :class="[classBase + 'app', grayBody ? 'gary-body' : '']">
    <router-view />
    <!-- 登录 -->
    <login />
    <!-- 实名认证 -->
    <auth-real-name />
    <!-- 青少年检查 -->
    <check-underage />
  </div>
</template>
<script>
// import { mapGetters } from "vuex";FD
// plugins
import eventBus from "@/plugins/eventBus";
import Login from "@/layout/wiki/components/login";
import AuthRealName from "@/layout/wiki/components/authRealName.vue";
import CheckUnderage from "@/layout/wiki/components/checkUnderage.vue";

export default {
  components: {
    Login,
    AuthRealName,
    CheckUnderage
  },
  data() {
    return {
      token: this.$route.query.token || "",
      grayBody: false
    };
  },
  computed: {},
  watch: {
    $route: "routeWatch"
  },
  async created() {
    // 设置网页灰色
    this.routeWatch();
  },
  async beforeMount() {
    // 获取用户信息
    await this.$store.dispatch("getUserInfo");

    // 青少年模式检查
    // eventBus.$emit("checkUnderage");

    // 设置全局鼠标样式
    const mouseImg = this.$store.getters.gameInfo.mouse_css || "";
    if (mouseImg) {
      document.body.style.cursor = `url(${mouseImg}), auto`;
    }

    // 注册一个全局点击事件，用于下拉菜单等点空白区域关闭菜单等操作；
    window.addEventListener("click", this.windowClick);
    if (this.device.desktop) {
      document.querySelector("body").style.minWidth = "1400px";
    }
    if (window.isAppPage && window.goPage) {
      this.$router.replace(window.goPage);
    }
  },
  mounted() {
    // 页面登录刷新后跳转
    const login_status = sessionStorage.getItem("login_status");
    const need_jump_path = sessionStorage.getItem("login_jump_path");
    if (login_status == 1) {
      sessionStorage.removeItem("login_status");
      if (need_jump_path) {
        sessionStorage.removeItem("login_jump_path");
        this.$router.push(need_jump_path);
      }
    } else if (this.$route.name != "callback") {
      sessionStorage.removeItem("login_status");
      if (need_jump_path) {
        sessionStorage.removeItem("login_jump_path");
      }
    }
  },
  beforeDestroy() {
    // 页面注销时销毁事件
    window.removeEventListener("click", this.windowClick, false);
  },
  methods: {
    // 监听全局点击事件
    windowClick(event) {
      eventBus.$emit("windowClick", event);
    },
    // 监听路由
    routeWatch() {
      if (this.$route.meta.grayBody) {
        this.grayBody = true;
      } else {
        this.grayBody = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.pc-app {
  max-width: 100%;
  overflow: auto;
}

// 页面置灰
.gary-body {
  -webkit-filter: grayscale(100%); /* webkit */
  -moz-filter: grayscale(100%); /*firefox*/
  -ms-filter: grayscale(100%); /*ie9*/
  -o-filter: grayscale(100%); /*opera*/
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
</style>
<style>
.tippy-box[data-theme~="light"] {
  background-color: #fff;
  color: #404040;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.1);
}
.tippy-box[data-theme~="light"] .tippy-content,
.tippy-box[data-theme~="dark"] .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~="light"] .tippy-arrow {
  color: #fff;
}
.contextmenu {
  padding: 6px 0;
}
.contextmenu-item {
  line-height: 26px;
  padding: 0 10px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  white-space: nowrap;
}
.contextmenu-item:hover {
  color: #fff;
  background-color: #3e55e1;
}
</style>
