<template>
  <div :class="['banner-container', alias + '-banner']">
    <swiper
      ref="mySwiper"
      :auto-destroy="true"
      :auto-update="true"
      :options="device.desktop ? swiperOptions : wapOptions"
      class="swiper-box"
    >
      <template>
        <swiper-slide v-for="item in list" :key="item.id" class="banner-item">
          <a :href="item.jump_url || 'javascript:;'" :target="item.jump_url ? '_blank' : '_self'">
            <img :src="item.thumb" alt="" class="swiper-lazy" />
          </a>
        </swiper-slide>
      </template>
    </swiper>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "bannerContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        // loop: true,
        // autoplay: true,
        watchSlidesProgress: true,
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination"
        },
        on: {
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i);
              let slideProgress = this.slides[i].progress;
              let modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              let translate = slideProgress * modify * 759 + "px";
              let scale = 1 - Math.abs(slideProgress) / 5 / 2;
              let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform("translateX(" + translate + ") scale(" + scale + ")");
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          setTransition: function(transition) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i);
              slide.transition(transition);
            }
          }
        }
      },
      wapOptions: {
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        autoplay: true
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    handleJumpUrl(item) {
      console.log(item);
      if (item.jump_url) {
        window.open(item.jump_url);
      }
    }
  },
  watch: {},
  created() {
    // console.log(this.swiperOptions);
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.banner-container {
  width: 100%;
  height: 280px;
  padding-bottom: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  position: relative;

  .swiper-box {
    height: 100%;
  }

  .banner-item {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      background: #fff;
    }
  }

  /deep/ .swiper-slide {
    padding: 0 98px;

    img {
      border: 2px solid #fff;
      height: 260px;
    }
  }
}

.swiper-pagination {
  width: 100%;
  text-align: center;

  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 4px;
    background: #9f9f9f;
    border-radius: 3px;
    opacity: 1;
    margin: 0 2px;
  }

  /deep/ .swiper-pagination-bullet-active {
    width: 17px;
    background: #29c0f7;
  }
}
.ba-banner {
}
</style>
