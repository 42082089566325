<template>
  <div :class="['friend-links', list.length >= 9 ? 'scroll-links' : '']">
    <div class="left-btn" @click="onScroll(-1)">
      <i class="el-icon-caret-left"></i>
    </div>
    <div ref="scrollBox" class="link-group">
      <template >
        <el-tooltip v-for="(item, index) in list" :key="index" :content="item.desc" placement="top">
          <a :href="item.link" target="_blank">
            <img :src="item.icon" alt="" />
          </a>
        </el-tooltip>
      </template>
    </div>
    <div class="right-btn" @click="onScroll(1)">
      <i class="el-icon-caret-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "rowFriendLinks",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    onScroll(type) {
      let scrollLeft = this.$refs.scrollBox.scrollLeft;
      this.$refs.scrollBox.scrollTo({
        left: scrollLeft + type * 200,
        behavior: "smooth"
      });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.friend-links {
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  padding: 0 12px;
  height: 48px;
  justify-content: space-between;

  &.scroll-links {
    .link-group {
      width: calc(100% - 30px);
    }

    .left-btn,
    .right-btn {
      display: flex;
    }
  }

  .left-btn,
  .right-btn {
    width: 12px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #323232;
    font-size: 12px;
  }

  .link-group {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  a {
    display: inline-block;

    & + a {
      margin-left: 10px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
