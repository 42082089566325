<template>
  <router-link
    :class="['article-list-item']"
    :target="device.inApp ? '_blank' : '_self'"
    :to="getJumpWikiDetailUrl(item.game_alias || item.game.alias, item.game_id || item.game.id, item.id, true)"
  >
    <div v-if="showUser && layout != 'row'" class="top-user-box">
      <img v-lazy="handleImgSize(item.user.avatar, 'mini')" alt="" />
      <div class="r-wrapper">
        <div class="username">
          {{ item.user.username }} <span class="date">{{ item.updated_at | dateFromNow }}</span>
        </div>
        <user-tag-group :user="item.user" />
        <!--        <div class="date" v-else>{{ item.updated_at | dateFromNow }}</div>-->
      </div>
    </div>
    <div :class="['flex-box', `${item.thumb.length ? 'has-img' : ''}`, `flex-${layout}`]">
      <div class="text-box">
        <div class="title">
          <i v-if="item.is_top" class="iconfont icon-zhiding"></i><span v-html="xss(item.title)"></span>
        </div>
        <div class="desc" v-html="xss(handleSummary(item.summary))"></div>
      </div>
      <div v-if="item.thumb.length" class="img-box">
        <template v-for="(img, index) in item.thumb.split(',')">
          <div v-if="layout == 'row' ? index < 1 : index < 3" :key="index" class="item">
            <img v-lazy="handleImgSize(img, 'list')" alt="" />
          </div>
        </template>
      </div>
    </div>
    <div class="bottom-box">
      <div class="l-box">
        <span v-if="layout == 'row'" class="user">
          <img v-lazy="handleImgSize(item.user.avatar, 'mini')" alt="" />
          <span>{{ item.user.username }}</span>
        </span>
        <a v-if="showGame" class="game-name">
          <img v-lazy="handleImgSize(item.game.icon, 'mini')" alt="" />
          {{ item.game.name }}
        </a>
        <!--        <div v-if="layout != 'row'" class="view-count"><i class="iconfont icon-yanjing"/>{{ item.view_count }}</div>-->
      </div>
      <div class="data-box">
        <div><i class="iconfont icon-pinglun" /> {{ item.comment_count }}</div>
        <div><i class="iconfont icon-chayue"></i>{{ item.view_count }}</div>
      </div>
    </div>
  </router-link>
</template>

<script>
import UserTagGroup from "@/components/UserTag/UserTagGroup";
import { getJumpWikiDetailUrl } from "@/plugins/util";
export default {
  name: "WikiArticleListItem",
  mixins: [],
  props: {
    // 图片与标题布局  row, column
    layout: {
      type: String,
      default: "row"
    },
    item: {
      type: Object,
      default: () => {}
    },
    keyword: {
      type: String,
      default: ""
    },
    showUser: {
      type: Boolean,
      default: true
    },
    showGame: {
      type: Boolean,
      default: false
    },
    isUserHome: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: { UserTagGroup },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getJumpWikiDetailUrl,
    onClick(e) {
      console.log("1");
      console.log(e);
      if (this.isUserHome) {
        console.log("2");
        e.stopPropagation();
        e.preventDefault();
        // let url = `//${this.item.game.alias}.gamekee.com/${this.item.id}.html`;
        // window.open(url);
      }
    },
    goPage(item) {
      if (this.device.inApp) {
        this.jsToApp("app_goDetail", JSON.stringify(item));
      } else {
        this.$router.push(`/${item.id}.html`);
      }
    },
    handleSummary(text) {
      let str = text;
      if (this.keyword) {
        let reg = new RegExp(`${this.keyword}`, "gim");
        str = text.replace(reg, '<span style="color:red;">' + this.keyword + "</span>");
      }
      return str;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.article-list-item {
  border-bottom: 1px solid @border-basic;
  margin-bottom: 12px;
  padding-bottom: 12px;
  display: block;

  .top-user-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }

    .r-wrapper {
      padding-left: 10px;
      font-size: 12px;

      .username {
        max-width: 150px;
        color: #2f2f2f;
        font-weight: 500;
        line-height: 20px;
      }

      .date {
        color: #989898;
        font-weight: initial;
        margin-left: 5px;
      }
    }
  }

  .flex-box {
    display: flex;

    &.flex-row {
      justify-content: space-between;

      .text-box {
        width: 100%;
      }

      &.has-img {
        .text-box {
          width: calc(100% - 110px);
        }
      }

      .img-box {
        width: 96px;
        height: 60px;

        img {
          width: 96px;
          height: 60px;
          border-radius: 6px;
          object-fit: cover;
        }
      }
    }

    &.flex-column {
      flex-direction: column;

      .img-box {
        display: flex;

        .item {
          margin-right: 14px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 500px) {
          .item {
            width: calc((100% - 14px * 2) / 3);
            height: 90px;
          }
        }

        @media screen and (min-width: 500px) {
          .item {
            width: 140px;
            height: 90px;
            max-width: calc((100% - (14px * 2)) / 3);
          }
        }
      }
    }

    .text-box {
      cursor: pointer;

      .title {
        .ellipsis;
        width: auto;
        font-size: 14px;
        line-height: 24px;
        color: #171717;

        .iconfont {
          font-size: 16px;
          margin-right: 5px;
          color: #e74957;
        }
      }

      .desc {
        font-size: 12px;
        color: #868686;
        line-height: 18px;
        overflow: hidden;
        max-height: 36px;
      }
    }
  }

  .bottom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #575757;
    font-size: 12px;

    .l-box {
      display: flex;
      align-items: center;

      .game-name {
        font-size: 12px;
        color: #666;
        background: @border-basic;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 22px;
        margin-right: 14px;

        img {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          margin-right: 6px;
        }
      }

      .user {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 8px;
        }
      }
    }

    .iconfont {
      margin-right: 4px;
      font-size: 12px;
    }

    div {
      display: flex;
      align-items: center;
    }

    .data-box {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
      }

      > div + div {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
